import { gql } from '@apollo/client';

export const GET_PARTNERS_AUTOCOMPLETE = gql`
  query v1_partners_autocomplete($prm: indexInt) {
    v1_partners_autocomplete(prm: $prm) {
      id
      title
      city_id
    }
  }
`;

