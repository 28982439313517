import React, { FC, useState, Suspense, useEffect } from 'react';
import { MainHeader } from 'src/components/MainHeader';
import { MainSidebar } from 'src/components/MainSidebar';
import { Spiner } from 'src/components/Loaders';
import { DashboardProvider } from 'src/contexts/affiliates/dashboard';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentUser,
  setWLUser,
} from 'src/features/authentication/sliceAuthentication';
import { AffiliateIdMap } from 'src/helpers/usersIdMap';
import { useQuery } from '@apollo/client';
import { GET_AFFILIATE_MEMBER_VENDOR } from 'src/api/affiliates';
import {
  setShowVendorWarning,
  setShowWLPages,
  showVendorWarning,
} from 'src/redux/AffiliateMember/sliceMembers';

export const MainLayout: FC<{}> = ({ children }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const vendorWarning = useSelector(showVendorWarning);

  useQuery(GET_AFFILIATE_MEMBER_VENDOR, {
    onCompleted: (data) => {
      dispatch(setShowVendorWarning(!data.v1_affiliates_readMember.vendor_id));
      dispatch(
        setShowWLPages(
          !!data.v1_affiliates_readMember.whiteLabel &&
            ['allowed', 'approved'].includes(
              data.v1_affiliates_readMember.statusWL || ''
            )
        )
      );
      if (data.v1_affiliates_readMember.whiteLabel) {
        dispatch(setWLUser(data.v1_affiliates_readMember.whiteLabel));
      }
    },
    onError: (error) => {
      console.error(error);
    },
    variables: {
      prm: {
        id:
          AffiliateIdMap[user?.['cognito:username']] ||
          user?.['cognito:username'],
      },
    },
    skip: typeof vendorWarning !== 'undefined' || !user,
  });

  const { innerWidth: width } = window;
  let isOpen = width > 1200;
  if (localStorage.getItem('asidebar') === 'close') {
    isOpen = false;
  }
  const isBackdrop = width < 1020;
  const [open, setOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (open) {
      localStorage.setItem('asidebar', 'open');
    } else {
      localStorage.setItem('asidebar', 'close');
    }
  }, [open]);

  return (
    <DashboardProvider>
      <div className={classes.root}>
        {isBackdrop && open && (
          <div
            className={classes.backdrop}
            onClick={() => setOpen(false)}></div>
        )}
        <div className={classes.page_container}>
          <Suspense fallback={<Spiner fixed />}>
            <MainSidebar open={open} setOpen={setOpen} />
            <div className={classes.content_container}>
              <MainHeader open={open} setOpen={setOpen} />
              <div className={classes.page_content_container}>{children}</div>
            </div>
          </Suspense>
        </div>
      </div>
    </DashboardProvider>
  );
};
