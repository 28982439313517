import React, { createContext, useContext, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { auth } from 'src/features/authentication/sliceAuthentication';
import { useAffiliatesStats } from 'src/hooks/useAffiliatesStats';
import {
  IActivity,
  useActivitiesAutocomplete,
} from 'src/hooks/autocomplete/useActivitiesAutocomplete';
import {
  IDestination,
  useDestinationsAutocomplete,
} from 'src/hooks/autocomplete/useDestinationsAutocomplete';
import {
  ITrip,
  useProductsAutocomplete,
} from 'src/hooks/autocomplete/useProductsAutocomplete';
import {
  IDateStatisticItem,
  IHeadersStat,
  IPieChartElement,
  IStatisticItemWithTypes,
  IStats,
  IStatsFilter,
  IStatsPrm,
  ITableStatsElement,
  ITotalValues,
  ILastPayoutItem,
  TGroupBy,
  TPeriodData,
  IDashboardStatistic,
} from 'src/pages/Dashboard/interfaces';
import { getStatsData } from 'src/helpers/affiliates/dashboard';
import { AffiliateIdMap } from '../../helpers/usersIdMap';
import { ApolloQueryResult } from '@apollo/client';
import { useLastPayout } from 'src/hooks/useLastPayout';
import {
  IPartner,
  usePartnersAutocomplete,
} from 'src/hooks/autocomplete/usePartnersAutocomplete';

interface IDashboardContext {
  prm: IStatsPrm;
  setPrm: React.Dispatch<React.SetStateAction<IStatsPrm>>;
  groupBy: TGroupBy;
  setGroupBy: React.Dispatch<React.SetStateAction<TGroupBy>>;
  period: TPeriodData;
  setPeriod: React.Dispatch<React.SetStateAction<TPeriodData>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeFilter: () => void;
  applyFilter: (isClearFilters?: boolean) => void;
  destiationsLoading: boolean;
  activitiesLoading: boolean;
  tripsLoading: boolean;
  destinationsData: IDestination[];
  activitiesData: IActivity[];
  tripsData: ITrip[];
  partnersData: IPartner[];
  partnersLoading: boolean;
  setPartner: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDestination: React.Dispatch<React.SetStateAction<number | undefined>>;
  setActivity: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTrip: React.Dispatch<React.SetStateAction<number | undefined>>;
  destination?: number;
  activity?: number;
  trip?: number;
  partner?: number;
  tableData: ITableStatsElement[];
  lineChartData: IDateStatisticItem[];
  pieChartData: IPieChartElement[];
  loaded: boolean;
  headers: IHeadersStat;
  totals: ITotalValues;
  statElements: IStatisticItemWithTypes[];
  statElementsForFilters: IStatisticItemWithTypes[];
  data: IDashboardStatistic;
  lastPayout: ILastPayoutItem;
  refetch: (
    variables?:
      | Partial<{
          prm: IStatsPrm;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<IStats>> | void;
}

const initialData: IDashboardContext = {
  prm: {
    type: '30d',
  },
  setPrm: (): void => {
    throw new Error('setContext function must be overridden');
  },
  groupBy: 'slug',
  setGroupBy: (): void => {
    throw new Error('setContext function must be overridden');
  },
  period: '30d',
  setPeriod: (): void => {
    throw new Error('setContext function must be overridden');
  },
  open: false,
  setOpen: (): void => {
    throw new Error('setContext function must be overridden');
  },
  closeFilter: (): void => {
    throw new Error('setContext function must be overridden');
  },
  applyFilter: (): void => {
    throw new Error('setContext function must be overridden');
  },
  refetch: (): void => {
    throw new Error('setContext function must be overridden');
  },
  destiationsLoading: true,
  activitiesLoading: true,
  tripsLoading: true,
  destinationsData: [],
  activitiesData: [],
  tripsData: [],
  partnersData: [],
  partnersLoading: true,
  data: {},
  setDestination: (): void => {
    throw new Error('setContext function must be overridden');
  },
  setActivity: (): void => {
    throw new Error('setContext function must be overridden');
  },
  setTrip: (): void => {
    throw new Error('setContext function must be overridden');
  },
  setPartner: (): void => {
    throw new Error('setContext function must be overridden');
  },
  tableData: [],
  lineChartData: [],
  pieChartData: [],
  loaded: false,
  headers: {
    table: '',
    lineChart: '',
    pieChart: '',
    cart: '',
    pdf: '',
  },
  totals: {
    amount: 0,
    clicks: 0,
    sales: 0,
    conversions: 0,
    payouts: 0,
  },
  statElements: [],
  statElementsForFilters: [],
  lastPayout: {
    amount: 0,
    paidAt: 0,
  },
};

export const Dashboard = createContext<IDashboardContext>(initialData);

export function useDashboard() {
  return useContext(Dashboard);
}

export const DashboardProvider = ({ children }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useSelector(auth);
  const [prm, setPrm] = useState<IStatsPrm>({
    type: '30d',
    userId: `${
      AffiliateIdMap[user?.['cognito:username']] || user?.['cognito:username']
    }`,
  });
  const [filters, setFilters] = useState<IStatsFilter>({
    period: '30d',
    groupBy: 'slug',
  });
  const [groupBy, setGroupBy] = useState<TGroupBy>('slug');
  const [period, setPeriod] = useState<TPeriodData>('30d');
  const [destination, setDestination] = useState<number | undefined>(undefined);
  const [activity, setActivity] = useState<number | undefined>(undefined);
  const [partner, setPartner] = useState<number | undefined>(undefined);
  const [trip, setTrip] = useState<number | undefined>(undefined);
  const [tag, setTag] = useState<string | undefined>(undefined);

  const { loading, data, refetch } = useAffiliatesStats(prm);
  const { loading: lastPayoutLoading, data: lastPayout } = useLastPayout();

  const { data: destinationsData, loading: destiationsLoading } =
    useDestinationsAutocomplete(false);
  const { data: activitiesData, loading: activitiesLoading } =
    useActivitiesAutocomplete(false);
  const { data: tripsData, loading: tripsLoading } = useProductsAutocomplete(
    false,
    true
  );

  const { data: partnersData, loading: partnersLoading } =
    usePartnersAutocomplete(false);

  const closeFilter = () => {
    setOpen(false);
    setPeriod(filters.period);
    setGroupBy(filters.groupBy);
    setDestination(filters.destination);
    setActivity(filters.activity);
    setPartner(filters.partner);
    setTrip(filters.trip);
    setTag(filters.tag);
  };

  const applyFilter = (isClearFilters?: boolean) => {
    if (isClearFilters) {
      setFilters({
        groupBy: 'slug',
        period: 'full',
        destination: undefined,
        activity: undefined,
        partner: undefined,
        trip: undefined,
        tag: undefined,
      });
    } else {
      setFilters({
        groupBy,
        period,
        destination,
        partner,
        activity,
        trip,
        tag,
      });
    }

    if (prm.type !== period) {
      setPrm((prev) => ({
        ...prev,
        type: period,
      }));
    }
    setOpen(false);
  };

  const {
    tableData,
    lineChartData,
    pieChartData,
    headers,
    totals,
    statElements,
  } = useMemo(
    () =>
      getStatsData(
        data,
        filters,
        destinationsData,
        activitiesData,
        tripsData,
        partnersData,
        prm
      ),
    [
      activitiesData,
      data,
      destinationsData,
      prm,
      filters,
      tripsData,
      partnersData,
    ]
  );

  const { statElements: statElementsForFilters } = useMemo(
    () =>
      getStatsData(
        data,
        {
          ...filters,
          trip,
          partner,
          activity,
          destination,
        },
        destinationsData,
        activitiesData,
        tripsData,
        partnersData,
        prm
      ),
    [
      activitiesData,
      data,
      destinationsData,
      prm,
      filters,
      trip,
      activity,
      destination,
      tripsData,
      partnersData,
      partner,
    ]
  );

  const loaded = Boolean(
    !loading &&
      !destiationsLoading &&
      !activitiesLoading &&
      !tripsLoading &&
      !lastPayoutLoading &&
      !partnersLoading
  );

  const value = {
    prm,
    data,
    setPrm,
    open,
    setOpen,
    groupBy,
    setGroupBy,
    period,
    setPeriod,
    applyFilter,
    closeFilter,
    destinationsData,
    activitiesData,
    tripsData,
    destiationsLoading,
    activitiesLoading,
    tripsLoading,
    destination,
    activity,
    trip,
    setDestination,
    setActivity,
    setTrip,
    setTag,
    tag,
    tableData,
    lineChartData,
    pieChartData,
    loaded,
    headers,
    totals,
    statElements,
    statElementsForFilters,
    refetch,
    lastPayout,
    partnersData,
    partnersLoading,
    partner,
    setPartner,
  };

  return <Dashboard.Provider value={value}>{children}</Dashboard.Provider>;
};
