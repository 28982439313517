import moment from 'moment';
import {
  IDashboardStatistic,
  IDateStatisticItem,
  IHeadersStat,
  IPieChartElement,
  IStatisticItemWithTypes,
  IStatsFilter,
  IStatsPrm,
  ITableStatsElement,
  ITotalValues,
  TPeriodData,
} from 'src/pages/Dashboard/interfaces';
import { IActivity } from 'src/hooks/autocomplete/useActivitiesAutocomplete';
import { IDestination } from 'src/hooks/autocomplete/useDestinationsAutocomplete';
import { ITrip } from 'src/hooks/autocomplete/useProductsAutocomplete';
import config from 'src/config';
import { IPartner } from 'src/hooks/autocomplete/usePartnersAutocomplete';

const getRandomRGB = (index: number) => {
  const rgbs = [
    'rgba(39, 174, 96, 1)',
    'rgba(92, 143, 220, 1)',
    'rgba(117, 228, 137, 1)',
    'rgba(162, 223, 172, 1)',
    'rgba(80, 176, 97, 1)',
    'rgba(155, 200, 252, 1)',
    'rgba(106, 197, 122, 1)',
  ];

  return rgbs[index % rgbs.length];
};

const getName = (period: TPeriodData, date: string): string => {
  if (period === 'full') return moment(date, 'YYYY').format('YYYY') || date;
  if (period === '12m') return moment(date, 'YYYY-MM').format('MMM') || date;
  if (period === '30d')
    return moment(date, 'YYYY-MM-DD').format('MM/DD') || date;
  return moment(date, 'YYYY-MM-DD').format('ddd') || date;
};

const getInitialLineChartData = (
  period: TPeriodData
): {
  [day: string]: IDateStatisticItem;
} => {
  const data: {
    [day: string]: IDateStatisticItem;
  } = {};

  const count =
    period === '12m' ? 12 : period === 'full' ? 10 : period === '30d' ? 30 : 7;
  const format =
    period === '12m'
      ? 'MMM'
      : period === 'full'
      ? 'YYYY'
      : period === '30d'
      ? 'MM/DD'
      : 'ddd';

  const getLabel =
    period === '12m' ? 'MMMM YYYY' : period === 'full' ? 'YYYY' : 'DD MMMM';

  const type =
    period === '12m' ? 'months' : period === 'full' ? 'years' : 'days';

  for (let i = 0; i < count; i++) {
    let date = moment().subtract(i, type).format(format);
    data[date] = {
      name: date,
      click: 0,
      sales: 0,
      amount: 0,
      order: i,
      label: moment().subtract(i, type).format(getLabel),
    };
  }

  return data;
};

const getTitle = (title: string) => {
  const re = /_/gi;
  return title.replace(re, '/');
};

export const addType = (
  title: string,
  destinations: IDestination[],
  activities: IActivity[],
  partners: IPartner[],
  trips: ITrip[]
): {
  destination_ids: number[];
  activity_ids: number[];
  partner_id: number[];
  trip_id: number;
} => {
  const link = title;
  const splitLink = link.split('/');
  let tripId: number = 0;
  let partnerId: number[] = [];
  let activityIds: number[] = [];
  let destinationIds: number[] = [];

  if (link.includes('partners')) {
    const slug = splitLink[2];
    const findedPartners = partners.filter(
      (i) =>
        i.title.split(' ').join('-').toLocaleLowerCase() ===
        slug.toLocaleLowerCase()
    );

    if (findedPartners.length) {
      partnerId.push(...findedPartners.map((i) => +i.id));
    }
  }

  if (link.includes('details')) {
    const trip_id = splitLink.pop();
    const findedTrip = trips.find((t) => t.id === +(trip_id || 0));

    if (findedTrip) {
      tripId = findedTrip.id;
      const findedActivities = activities.filter(
        (i) =>
          +i.id === +findedTrip.activity_id ||
          (findedTrip.activity2_id && +i.id === +findedTrip.activity2_id) ||
          (findedTrip.activity3_id && +i.id === +findedTrip.activity3_id)
      );

      if (findedActivities.length) {
        activityIds.push(...findedActivities.map((i) => +i.id));
      }
      const findedDestinations = destinations.filter(
        (i) =>
          i.id === findedTrip.city_id ||
          (findedTrip.city_id2 && i.id === findedTrip.city_id2) ||
          (findedTrip.city_id3 && i.id === findedTrip.city_id3) ||
          (findedTrip.city_id4 && i.id === findedTrip.city_id4) ||
          (findedTrip.city_id5 && i.id === findedTrip.city_id5) ||
          (findedTrip.city_id6 && i.id === findedTrip.city_id6)
      );
      if (findedDestinations.length) {
        destinationIds.push(...findedDestinations.map((i) => +i.id));
      }
      const findedPartners = partners.filter(
        (i) => +i.id === findedTrip.partner_id
      );
      if (findedPartners.length) {
        partnerId.push(...findedPartners.map((i) => +i.id));
      }
    }
  }

  if (link.includes('attractions')) {
    const destination_slug = splitLink[2];
    const activity_slug = splitLink[3];

    const findedDestinations = destinations.filter(
      (i) =>
        i.name.split(' ').join('-').toLocaleLowerCase() ===
        destination_slug.toLocaleLowerCase()
    );

    if (findedDestinations.length) {
      destinationIds.push(...findedDestinations.map((i) => +i.id));
    }

    if (activity_slug) {
      const findedActivities = activities.filter(
        (i) => i.slug === activity_slug
      );
      if (findedActivities.length) {
        activityIds.push(...findedActivities.map((i) => +i.id));
      }
    }
  }

  return {
    destination_ids: destinationIds,
    activity_ids: activityIds,
    trip_id: tripId,
    partner_id: partnerId,
  };
};

export const getStatsData = (
  data: IDashboardStatistic,
  filters: IStatsFilter,
  destinations: IDestination[] = [],
  activities: IActivity[] = [],
  trips: ITrip[] = [],
  partners: IPartner[] = [],
  prm: IStatsPrm
): {
  tableData: ITableStatsElement[];
  lineChartData: IDateStatisticItem[];
  pieChartData: IPieChartElement[];
  headers: IHeadersStat;
  totals: ITotalValues;
  statElements: IStatisticItemWithTypes[];
} => {
  const statElements: IStatisticItemWithTypes[] = [];

  const normalizeData = Object.keys(data).reduce(
    (
      acc: {
        tableData: {
          [day: string]: ITableStatsElement;
        };
        lineChartData: {
          [day: string]: IDateStatisticItem;
        };
        total: {
          clicks: number;
          sales: number;
          amount: number;
          payouts: number;
        };
      },
      curr
    ) => {
      const lineChartTitle = getName(prm.type, curr);

      Object.keys(data[curr]).forEach((title) => {
        const elem = data[curr][title];

        let normalisedTitle = getTitle(title);
        let isValid = true;

        const statElem: IStatisticItemWithTypes = {
          ...elem,
          link:
            normalisedTitle !== '/'
              ? `${config.siteUrl}${normalisedTitle}`
              : config.siteUrl,
          ...addType(
            normalisedTitle,
            destinations,
            activities,
            partners,
            trips
          ),
        };

        if (
          (filters.groupBy === 'slug' &&
            (((filters.destination || filters.destination === 0) &&
              !statElem.destination_ids?.includes(filters.destination)) ||
              ((filters.partner || filters.partner === 0) &&
                !statElem.partner_id?.includes(filters.partner)) ||
              ((filters.activity || filters.activity === 0) &&
                !statElem.activity_ids?.includes(filters.activity)) ||
              ((filters.trip || filters.trip === 0) &&
                statElem.trip_id !== filters.trip))) ||
          (filters.groupBy === 'affiliates' &&
            filters.tag &&
            (!statElem.tags?.length || !statElem.tags.includes(filters.tag)))
        ) {
          isValid = false;
        }
        if (isValid) {
          statElements.push(statElem);
          switch (filters.groupBy) {
            case 'destination':
              normalisedTitle =
                destinations.find((i) =>
                  statElem.destination_ids?.includes(+i.id)
                )?.name || 'other';
              break;
            case 'activities':
              normalisedTitle =
                activities.find((i) => statElem.activity_ids?.includes(+i.id))
                  ?.name || 'other';
              break;
            case 'partner':
              normalisedTitle =
                partners.find((i) => statElem.partner_id?.includes(+i.id))
                  ?.title || 'other';
              break;
            case 'trips':
              normalisedTitle =
                trips.find((i) => +i.id === statElem.trip_id)?.name || 'other';
              break;
            default:
              break;
          }

          const currLineChartData = acc.lineChartData[lineChartTitle];
          const currTableData = acc.tableData[normalisedTitle];
          const { click, sales, amount, payouts, id } = statElem;

          acc.lineChartData[lineChartTitle] = {
            ...currLineChartData,
            click: (currLineChartData?.click || 0) + (click || 0),
            sales: (currLineChartData?.sales || 0) + (sales || 0),
            amount: (currLineChartData?.amount || 0) + (amount || 0),
            payouts: (currLineChartData?.payouts || 0) + (payouts || 0),
          };
          acc.tableData[normalisedTitle] = {
            title: currTableData?.title || normalisedTitle,
            id: currTableData?.id || id,
            click: (currTableData?.click || 0) + (click || 0),
            sales: (currTableData?.sales || 0) + (sales || 0),
            amount: (currTableData?.amount || 0) + (amount || 0),
            payouts: (currTableData?.payouts || 0) + (payouts || 0),
            conversion: 0,
          };
          acc.total = {
            clicks: acc.total.clicks + (click || 0),
            sales: acc.total.sales + (sales || 0),
            amount: acc.total.amount + (amount || 0),
            payouts: acc.total.payouts + (payouts || 0),
          };
        }
      });

      return acc;
    },
    {
      tableData: {},
      lineChartData: getInitialLineChartData(prm.type),
      total: {
        clicks: 0,
        sales: 0,
        amount: 0,
        payouts: 0,
      },
    }
  );

  if (!statElements.find((i) => i.link === config.siteUrl)) {
    statElements.unshift({
      click: 0,
      link: config.siteUrl,
      destination_ids: [],
      activity_ids: [],
      partner_id: [],
      trip_id: 0,
    });
  }

  const lineChartData = Object.values(normalizeData.lineChartData)
    .map((i) => ({
      ...i,
      clicks: i.click,
      orders: i.sales,
      conversion: i.click ? (i.sales || 0 / i.click) * 100 : 0,
      amount: (i.amount || 0) / 100,
      payouts: (i.payouts || 0) / 100,
    }))
    .sort((a, b) => b.order - a.order);

  const tableData = Object.values(normalizeData.tableData)
    .filter((i) => !!(i.click + i.amount + i.payouts + i.sales))
    .map((i) => ({
      ...i,
      conversion: (i.sales / i.click) * 100,
      amount: i.amount / 100,
      payouts: i.payouts / 100,
    }));

  const isAmount = Boolean(normalizeData.total.amount);
  const sortField = isAmount ? 'amount' : 'click';
  const totalValue = isAmount
    ? normalizeData.total.amount / 100
    : normalizeData.total.clicks;

  const otherPiesChart: IPieChartElement = {
    link: 'other',
    click: 0,
    sales: 0,
    amount: 0,
    color: '',
    conversion: 0,
    payouts: 0,
  };
  const visiblePiesChart = tableData.reduce(
    (acc: IPieChartElement[], curr, idx) => {
      const currValue = isAmount ? curr.amount : curr.click;
      const piePercent = (currValue / totalValue) * 100;

      if (acc.length < 14 && piePercent > 2) {
        return [
          ...acc,
          {
            link: curr.title,
            click: curr.click,
            amount: curr.amount,
            sales: curr.sales,
            color: getRandomRGB(acc.length + 1),
            conversion: curr.sales ? (curr.click / curr.sales) * 100 : 0,
            payouts: curr.payouts,
          },
        ];
      }

      otherPiesChart.click! += curr?.click || 0;
      otherPiesChart.sales! += curr?.sales || 0;
      otherPiesChart.amount! += curr?.amount || 0;
      otherPiesChart.payouts! += curr?.payouts || 0;

      return acc;
    },
    []
  );

  const pieChartData = [
    ...visiblePiesChart.sort((a, b) => b[sortField]! - a[sortField]!),
    {
      ...otherPiesChart,
      conversion: otherPiesChart.sales
        ? (otherPiesChart.click! / otherPiesChart.sales) * 100
        : 0,
      color: getRandomRGB(visiblePiesChart.length + 1),
    },
  ];
  const getNewLineChartData = () => {
    if (prm.type === '30d') {
      return lineChartData.filter((i) => !!i.name);
    }
    return lineChartData;
  };

  const newLineChartData = getNewLineChartData();

  const headers = {
    table: `Statistics by ${
      filters.groupBy === 'slug' ? 'links' : filters.groupBy
    }`,
    lineChart: `${newLineChartData[0].name} - ${
      newLineChartData[newLineChartData.length - 1].name
    }`,
    pdf: `${lineChartData[0].label} - ${
      lineChartData[lineChartData.length - 1].label
    }`,
    pieChart: `By ${filters.groupBy === 'slug' ? 'links' : filters.groupBy}`,
    cart:
      prm.type === 'full'
        ? 'for all time'
        : prm.type === '12m'
        ? 'for last year'
        : prm.type === '30d'
        ? 'for last 30 days'
        : 'for last 7 days',
  };

  const totals: ITotalValues = {
    ...normalizeData.total,
    amount: normalizeData.total.amount / 100,
    conversions: normalizeData.total.clicks
      ? ((normalizeData.total.sales || 0) / normalizeData.total.clicks) * 100
      : 0,
    payouts: normalizeData.total.payouts / 100,
  };

  return {
    tableData,
    lineChartData,
    pieChartData,
    headers,
    totals,
    statElements,
  };
};
