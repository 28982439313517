import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { setNotification } from 'src/features/notifications/sliceNotifications';
import { auth } from 'src/features/authentication/sliceAuthentication';
import { AffiliateIdMap } from 'src/helpers/usersIdMap';
import {
  ILastPayout,
  ILastPayoutPrm,
  ILastPayoutItem,
} from 'src/pages/Dashboard/interfaces';
import { GET_LAST_PAYOUT } from 'src/api/payout';

export const useLastPayout = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const userId = user
    ? AffiliateIdMap[user['cognito:username']] || user['cognito:username']
    : 0;
  const [data, setData] = useState<ILastPayoutItem>({
    paidAt: 0,
    amount: 0,
  });

  const { loading } = useQuery<ILastPayout, { prm: ILastPayoutPrm }>(
    GET_LAST_PAYOUT,
    {
      onCompleted: (receivedData) => {
        if (receivedData.v1_affiliates_readPaymentList.items.length) {
          setData(receivedData.v1_affiliates_readPaymentList.items[0]);
        }
      },
      onError: (error) => {
        dispatch(
          setNotification({
            type: 'error',
            message: error.message,
            duration: 10000,
          })
        );
      },
      pollInterval: 1800000,
      variables: {
        prm: {
          limit: 1,
          filter: {
            status: 'succeeded',
            userId,
          },
        },
      },
      skip: !user,
    }
  );

  return {
    data,
    loading,
  };
};
