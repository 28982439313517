export const HOME_PATH = '/';
export const FAQ_PATH = '/faq';
export const DASHBOARD_PATH = '/dashboard';
export const COMMISSIONS_PATH = '/orders';
export const PROFILE_PATH = '/profile';
export const PAYOUT_PATH = '/payout';
export const LINKS_PATH = '/links';
export const NEW_ACTIVITIES_PATH = '/new-activities';
export const DEALS_PROMOTIONS_PATH = '/deals_promotions';
export const TOP_ATTRACTIONS_PATH = '/insights';
export const TOP_ATTRACTIONS_DETAILS_PATH = '/insights_details/:type/:slug';
export const DISABLED_TRIPS_PATH = '/disabled_activities';
export const CITY_WIDGET_PATH = '/city_widget';
export const ACTIVITY_WIDGET_PATH = '/activity_widget';
export const LOGIN_PATH = '/login';
export const ADMIN_LOGIN_PATH = '/admin-login/:challenge';
export const REGISTRATION_PATH = '/registration';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const CONFIRM_PASSWORD_PATH = '/confirm-password';
export const UNAUTHORIZED_PATH = '/401';
export const INTERNAL_SERVER_PATH = '/500';
export const NOT_FOUND_PATH = '*';

export const WL_CONTENT_PATH = '/content';
export const WL_TRIPS_PATH = '/trips';
export const WL_INFO_APP_PATH = '/info-and-application';