import React, { useState } from 'react';
import { Box, Button, Container, Divider } from '@material-ui/core';
import { ReactComponent as LoginIcon } from 'src/assets/icons/login.svg';
import { ReactComponent as Logo } from 'src/assets/logo/FullLogo.svg';
import { Link, NavLink } from 'react-router-dom';
import { useStyles } from './styles';
import { Close, Menu } from '@material-ui/icons';

export const AuthHeader = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const navClass = !open ? classes.navbar : `${classes.navbar} active`;

  const onHandlerRedirect = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Box className={classes.root}>
          <Link to="/">
            <div className={classes.logo}>
              <Logo />
            </div>
          </Link>
          <Box className={navClass}>
            <NavLink
              to="/"
              exact
              activeClassName="active"
              onClick={onHandlerRedirect}
              className={classes.header_item}>
              How it works
            </NavLink>
            <NavLink
              activeClassName="active"
              onClick={onHandlerRedirect}
              to="/faq"
              className={classes.header_item}>
              FAQ
            </NavLink>
            <NavLink
              activeClassName="active"
              onClick={onHandlerRedirect}
              to="/login"
              className={classes.header_item}>
              <LoginIcon style={{ marginRight: '8px' }} />
              Login
            </NavLink>
            <Divider className={classes.divider} />
            <Button
              to="/registration"
              component={Link}
              onClick={onHandlerRedirect}
              color="primary"
              variant="contained"
              className={classes.header_button}>
              Create an account
            </Button>
          </Box>
          <Box
            className={classes.navbar_toggle}
            onClick={() => setOpen((prev) => !prev)}>
            {open ? <Close /> : <Menu />}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
