import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { auth, signOut } from 'src/features/authentication/sliceAuthentication';
import { useStyles } from './styles';
import { ReactComponent as MenuIcon } from 'src/assets/icons/menu.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exitToApp.svg';

interface IMainHeaderProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MainHeader = ({ open, setOpen }: IMainHeaderProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(auth);

  const onSignOut = () => dispatch(signOut());

  return (
    <Box className={classes.root}>
      <Box className={classes.left_container}>
        {!open && (
          <Box
            className={classes.menu_container}
            onClick={() => setOpen((prev) => !prev)}>
            <Box className={classes.menu_box}>
              <MenuIcon />
            </Box>
          </Box>
        )}

        <Typography className={classes.title}>
          {user?.given_name + ' ' + user?.family_name}
          <span className={classes.sub_title}>
            {user?.['cognito:username']}
          </span>
        </Typography>
      </Box>

      <Box className={classes.exitApp}>
        <Box onClick={onSignOut}>
          <ExitIcon />
        </Box>
      </Box>
    </Box>
  );
};
