import { makeStyles } from '@material-ui/core/styles';
import { Fonts } from 'src/themes/Fonts';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
    top: 0,
    backgroundColor: Palette.dark_1,
    boxShadow: '0px 3.89312px 23.3587px rgba(17, 15, 15, 0.53)',
  },
  root: {
    display: 'flex',
    padding: '0',
    height: '65px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      top: '50px',
      left: '0',
      width: '100%',
      height: 'calc(100vh - 50px)',
      backgroundColor: Palette.dark_1,
      borderTop: `0.6px solid ${Palette.dark_3}`,
      transition: 'all ease 0.4s',
      transform: 'translateX(100%)',
      '&.active': {
        transform: 'translateX(0)',
      },
      '& $header_button': {
        marginLeft: 0,
        marginTop: '40px',
      },
      '& $header_item': {
        padding: 0,
        height: '60px',
      },
    },
  },
  divider: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '140px',
      backgroundColor: Palette.dark_3,
      marginTop: '20px',
    },
  },
  navbar_toggle: {
    display: 'none',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  title: {
    color: Palette.dark_3,
    fontWeight: 500,
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.2',
      fontSize: '18px',
    },
  },
  logo: {
    '& svg': {
      display: 'block',
      height: '36px',
      [theme.breakpoints.down('sm')]: {
        height: '32px',
      },
    },
  },
  header_item: {
    ...Fonts.desktop.body_2.regular,
    textTransform: 'none',
    color: Palette.white,
    marginLeft: '20px',
    textDecoration: 'none',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '&.active': {
      color: Palette.brand,
      '& svg': {
        color: Palette.brand,
      },
    },
  },
  header_button: {
    background: Palette.gradient,
    width: '200px',
    boxShadow:
      '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    borderRadius: '50px',
    padding: '6.5px 25.5px',
    textTransform: 'none',
    letterSpacing: '0px',
    color: Palette.white,
    ...Fonts.desktop.body_2.medium,
    marginLeft: '30px',
    fontFamily: 'Montserrat',
    '&:hover': {
      background: Palette.gradient_hover,
      boxShadow:
        '0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)',
    },
    '& span': {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      width: '220px',
      padding: '8px 28px',
    },
  },
}));
