import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  ISetInitialPrmProps,
  sqlWhereRule,
  IInitialState,
  IChangePrmProps,
  IPrm,
  IAffiliateFilter,
} from './interfaces';
import { RootState } from 'src/redux/store';

// const initialPrm = {
//   limit: 15,
//   offset: '0',
// };

const initialState: IInitialState = {
  userId: '',
  page: '',
  prm: null,
  filter: [],
  sorting: [],
  search: null,
  calendar: null,
  scrollLoading: false,
  changeLoading: true,
  history: {},
  availableStates: [],
};

const changePrm = (data: IChangePrmProps): null | IPrm => {
  const { prm, filter, sorting, search, calendar, page } = data;
  const currentFilter = prm && prm.where;

  let newFilter: sqlWhereRule[] = [];
  const isNewFormat: boolean = prm
    ? !!prm.where_scheme && prm.where_scheme === 'and_for_or'
    : false;
  let newFormatFilter: sqlWhereRule[][] = [];

  if (prm) {
    if (prm.isApproved) {
      delete prm.pager;
    } else {
      prm.offset = '0';
    }
  }

  if (sorting) {
    sorting.forEach((i) => {
      if (prm && i.active && i.order) {
        prm.order = { field: i.id, asc: i.order === 'asc' };
      }
    });
  }

  if (
    calendar &&
    calendar.isActive &&
    calendar.time.startTime &&
    calendar.time.endTime
  ) {
    newFilter = [
      ...newFilter,
      {
        field: calendar.field,
        operator: 'ge',
        value: calendar.time.startTime,
        valueType: calendar.valueType,
      },
      {
        field: calendar.field,
        operator: 'le',
        value: calendar.time.endTime,
        valueType: calendar.valueType,
      },
    ];
  }

  if (filter) {
    filter.forEach((i) => {
      if (i.operator === 'in') {
        let multiselectFilter: any = [];
        i.values.forEach((v) => {
          if (v.isSelected === true) {
            const multValue = v.value;
            multiselectFilter.push(multValue);
          }
        });
        if (multiselectFilter.length) {
          newFilter = [
            ...newFilter,
            {
              field: i.field,
              operator: i.operator,
              value: JSON.stringify(multiselectFilter),
              valueType: 'JSON',
            },
          ];
        }
      } else {
        i.values.forEach((v) => {
          if (v.isSelected === true) {
            newFilter = [
              ...newFilter,
              {
                field: i.field,
                operator: i.operator,
                value: v.value,
                valueType: i.valueType,
              },
            ];
          }
        });
      }
    });

    if (isNewFormat) {
      newFilter.forEach((item: sqlWhereRule) => {
        newFormatFilter.push([item]);
      });
    }

    if (search && search.isActive) {
      if (isNewFormat) {
        newFormatFilter.push([
          {
            value: `%${search.value}%`,
            field: search.fields[0],
            operator: 'like',
          },
        ]);
      } else {
        search.fields.forEach((field, idx) => {
          if (currentFilter) {
            currentFilter[idx] = [
              ...newFilter,
              {
                value: `%${search.value}%`,
                field,
                operator: 'like',
              },
            ];
          }
        });
      }
    }

    if (prm) {
      if (prm.offset) {
        prm.where = isNewFormat ? newFormatFilter : [newFilter];
      }
    }
  }

  if (!!prm && (page === 'commissions' || page === 'payout')) {
    const affiliatesFilter: IAffiliateFilter = {};

    const statusValue = newFilter.find((f) => f.field === 'status')?.value;
    const typeValue = newFilter.find((f) => f.field === 'utmType')?.value;
    const isArchived = newFilter.find((f) => f.field === 'isArchived')?.value;
    const searchValue = search?.value;

    if (statusValue) {
      affiliatesFilter.status = statusValue;
    }

    if (typeValue) {
      affiliatesFilter.utmType = typeValue as string;
    }

    if (searchValue) {
      affiliatesFilter.search = searchValue;
    }

    if (isArchived || prm.isArchived) {
      const isArchivedValue = Boolean(+(isArchived || 0));

      if (isArchivedValue !== prm.isArchived) {
        delete affiliatesFilter.status;
      }

      prm.isArchived = isArchivedValue;
    }

    prm.filter = affiliatesFilter;

    delete prm.offset;
    delete prm.where;
    delete prm.pager;

    return prm;
  }

  return prm;
};

export const tableSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setInitialState: (state, action: PayloadAction<ISetInitialPrmProps>) => {
      const { payload } = action;
      const { page, filter, sorting, prm, search, calendar } = payload;
      state.page = page;
      state.filter = filter;
      state.sorting = sorting;
      state.prm = prm ? prm : null;
      state.search = search ? search : null;
      state.calendar = calendar ? calendar : state.calendar;
      state.history[page] = {
        filter,
        sorting,
        prm: prm ? prm : null,
        search: search ? search : null,
        calendar: calendar ? calendar : state.calendar,
      };
    },
    setLoading: (
      state,
      action: PayloadAction<{ type: 'scroll' | 'change'; value: boolean }>
    ) => {
      const { payload } = action;
      const { type, value } = payload;
      if (type === 'scroll') {
        state.scrollLoading = value;
      }
      if (type === 'change') {
        state.changeLoading = value;
      }
    },
    setInitialPrm: (state, action: PayloadAction<ISetInitialPrmProps>) => {
      const { payload } = action;
      const { page, filter, sorting, search, prm, calendar, userId } = payload;

      state.changeLoading = true;
      if (state.prm) {
        if (page === 'content') {
          state.prm.isApproved = payload.prm?.isApproved;
          state.prm.type = payload.prm?.type;
        } else if (page !== 'commissions' && page !== 'payout') {
          state.prm.offset = '0';
        }
        if (page === 'commissions' || page === 'payout') {
          state.prm.pager = null;
        }
      }
      if (page !== state.page) {
        const historyPage = state.history[page];

        if (historyPage) {
          state.userId = userId;
          state.page = page;
          state.filter = historyPage.filter;
          state.sorting = historyPage.sorting;
          state.search = historyPage.search;
          state.calendar = historyPage.calendar;
          state.prm = historyPage.prm
            ? {
                ...historyPage.prm,
                pager: null,
              }
            : null;

          return;
        }

        state.userId = userId;
        state.page = page;
        state.filter = filter;
        state.sorting = sorting;
        state.search = search;
        state.calendar = calendar;
        state.prm = prm;
        state.history[page] = {
          filter,
          sorting,
          search,
          prm,
          calendar,
        };
        return;
      }
      state.history[state.page] = {
        ...state.history[state.page],
        prm: state.prm,
      };
    },
    changeInitialFilter: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { page, filter } = payload;

      if (page === state.page) {
        state.filter = filter;
      }
    },
    setOffset: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      if (
        state.page === 'content' ||
        state.page === 'commissions' ||
        state.page === 'payout'
      ) {
        state.pager = payload;
      } else {
        state.offset = payload;
      }
    },
    setOffsetLoadData: (state) => {
      if (state.prm) {
        if (
          state.page === 'content' ||
          state.page === 'commissions' ||
          state.page === 'payout'
        ) {
          state.prm.pager = state.pager;
        } else {
          state.prm.offset = state.offset;
        }
      }
      state.history[state.page] = {
        ...state.history[state.page],
        prm: state.prm,
      };
    },
    setTime: (
      state,
      action: PayloadAction<{ start?: string | null; end?: string | null }>
    ) => {
      const { payload } = action;
      if (state.calendar) {
        state.calendar.isActive = !payload.start || !payload.end ? false : true;
        state.calendar.time.startTime = payload.start || null;
        state.calendar.time.endTime = payload.end || null;
      }
      if (state.calendar) {
        if (
          state.calendar.isActive &&
          state.calendar.time.startTime &&
          state.calendar.time.endTime &&
          state.calendar.field
        ) {
          state.filter.forEach((i) => {
            if (i.field === state.calendar?.field) {
              i.values.forEach((v) => {
                v.isSelected = false;
              });
            }
          });
        }
        if (
          (state.calendar.time.startTime && state.calendar.time.endTime) ||
          (!state.calendar.time.startTime && !state.calendar.time.endTime)
        ) {
          state.prm = changePrm({
            prm: state.prm,
            filter: state.filter,
            search: state.search,
            calendar: state.calendar,
          });
        }
      }
      state.history[state.page] = {
        ...state.history[state.page],
        calendar: state.calendar,
        filter: state.filter,
        prm: state.prm,
      };
    },
    setSearch: (state, action: PayloadAction<string | null>) => {
      const { payload } = action;
      if (state.search) {
        state.search.isActive = payload ? true : false;
        state.search.value = payload;
      }
      state.prm = changePrm({
        prm: state.prm,
        filter: state.filter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        search: state.search,
        prm: state.prm,
      };
    },
    setSorting: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const newSorting = state.sorting;
      newSorting.forEach((item, idx) => {
        if (idx === payload) {
          item.active = true;
          item.order = item.order === 'asc' ? 'desc' : 'asc';
        } else {
          item.order = undefined;
          item.active = false;
        }
      });
      state.prm = changePrm({
        prm: state.prm,
        sorting: newSorting,
        filter: state.filter,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        sorting: state.sorting,
        filter: state.filter,
        prm: state.prm,
      };
    },
    setSelector: (state, action) => {
      const { payload } = action;
      const { field, value } = payload;
      const fieldIdx = state.filter.findIndex((f) => f.field === field);

      let newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values[0].isSelected = !!value;
      newFilter[fieldIdx].values[0].value = value;

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    setFilter: (
      state,
      action: PayloadAction<{ field: string; valueIdx: number }>
    ) => {
      const { payload } = action;
      const { field, valueIdx } = payload;

      const fieldIdx = state.filter.findIndex((f) => f.field === field);
      const newFilter = state.filter;

      const newSqlFilterPrm = state.prm;
      const isMultiselect = newFilter[fieldIdx].multiselect;
      const isSelected = newFilter[fieldIdx].values[valueIdx].isSelected;

      if (isSelected || isMultiselect) {
        newFilter[fieldIdx].values[valueIdx].isSelected = !isSelected;
      } else {
        newFilter[fieldIdx].values.forEach((value, idx) => {
          if (
            newFilter[fieldIdx].field === state.calendar?.field &&
            idx === valueIdx
          ) {
            if (state.calendar?.isActive) {
              state.calendar.isActive = false;
              state.calendar.time.startTime = null;
              state.calendar.time.endTime = null;
            }
          }
          value.isSelected = idx === valueIdx ? true : false;
        });
      }

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    setAvailableStates: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      state.availableStates = payload;
    },
    clearFilterStates: (state) => {
      const fieldIdx = state.filter.findIndex((f) => f.field === 'state_id');
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values.forEach((f) => (f.isSelected = false));
      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
        page: state.page,
      });
      state.history[state.page] = {
        ...state.history[state.page],
        filter: state.filter,
        calendar: state.calendar,
        prm: state.prm,
      };
    },
    clearFilterDestination: (state) => {
      const fieldIdx = state.filter.findIndex((f) => f.field === 'city_id');
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values[0].value = '';
      newFilter[fieldIdx].values[0].isSelected = false;

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
      });
    },
    clearFilterCategory: (state) => {
      const fieldIdx = state.filter.findIndex((f) => f.field === 'category_id');
      const newFilter = state.filter;
      const newSqlFilterPrm = state.prm;

      newFilter[fieldIdx].values[0].value = '';
      newFilter[fieldIdx].values[0].isSelected = false;

      state.prm = changePrm({
        prm: newSqlFilterPrm,
        filter: newFilter,
        search: state.search,
        calendar: state.calendar,
      });
    },
  },
});

export const {
  setInitialState,
  setFilter,
  setSorting,
  setLoading,
  setSearch,
  setOffset,
  setOffsetLoadData,
  setInitialPrm,
  setTime,
  setSelector,
  setAvailableStates,
  clearFilterStates,
  clearFilterDestination,
  clearFilterCategory,
  changeInitialFilter,
} = tableSlice.actions;

export const tableState = (state: RootState) => state.tables;
export const filter = (state: RootState) => state.tables.filter;
export const sorting = (state: RootState) => state.tables.sorting;
export const prm = (state: RootState) => state.tables.prm;
export const scrollLoading = (state: RootState) => state.tables.scrollLoading;
export const changeLoading = (state: RootState) => state.tables.changeLoading;
export const search = (state: RootState) => state.tables.search;
export const calendar = (state: RootState) => state.tables.calendar;
export const offset = (state: RootState) => state.tables.offset;
export const pager = (state: RootState) => state.tables.pager;
export const page = (state: RootState) => state.tables.page;
export const history = (state: RootState) => state.tables.history;
export const availableStates = (state: RootState) =>
  state.tables.availableStates;
export const activeFilterStates = (state: RootState) => {
  const currentFilter = filter(state);
  const fieldIdx = currentFilter.findIndex((f) => f.field === 'state_id');
  const activeStates =
    currentFilter[fieldIdx]?.values.filter((s: any) => s.isSelected) || [];
  return activeStates.map((s) => s.label);
};
export const activeFilterDestinations = (state: RootState) => {
  const currentFilter = filter(state);
  const fieldIdx = currentFilter.findIndex((f) => f.field === 'city_id');
  const activeStates =
    currentFilter[fieldIdx]?.values.filter((s: any) => s.isSelected) || [];
  return activeStates.map((s) => s.value);
};

export default tableSlice.reducer;
