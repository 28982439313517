import React, { Dispatch, SetStateAction } from 'react';
import { Box, List } from '@material-ui/core';
import { ReactComponent as Dot } from 'src/assets/sidebar/dot.svg';
import NavigationItem from './NavigationItem';
import { config } from 'src/routes/config/navigation';
import { Palette } from 'src/themes/Palette';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { showWLPages } from 'src/redux/AffiliateMember/sliceMembers';

export const MainSidebarNavigation = ({
  open,
  setOpen,
}: IMainSidebarNavigationProps) => {
  const classes = useStyles();

  const isUserWL = useSelector(showWLPages);

  return (
    <Box className={classes.root}>
      {config.map((nav) => (
        <List key={nav.title}>
          <div className={classes.linkHeader}>
            <Box className={classes.sidebarDivider} />
            {open ? (
              <Box display="flex">
                {nav.title}
                {nav.title === 'White label' && (
                  <Box
                    px={'8px'}
                    ml={1}
                    bgcolor={Palette.brand_opacity_3}
                    borderRadius={'20px'}
                    style={{
                      color: Palette.brand,
                      fontSize: '12px',
                      fontWeight: 600,
                    }}>
                    NEW
                  </Box>
                )}
              </Box>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Dot />
              </div>
            )}
          </div>
          {nav.links.map((link, idx) => {
            if (!isUserWL && ['Trips', 'Content'].includes(link.title)) {
              return null;
            }
            return (
              <NavigationItem
                isOpenDrawer={open}
                setOpenDrawer={setOpen}
                link={link}
                key={link.title}
              />
            );
          })}
        </List>
      ))}
    </Box>
  );
};

interface IMainSidebarNavigationProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
