import { gql } from '@apollo/client';

export const GET_DESTINATIONS_AUTOCOMPLETE = gql`
  query v1_orders_destinations {
    v1_orders_destinations {
      id
      name
      categories
      stateCode
    }
  }
`;

export const GET_ACTIVITIES_AUTOCOMPLETE = gql`
  query v1_promocodes_activities($prm: v1_promocodes_activities_prm) {
    v1_promocodes_activities(prm: $prm) {
      id
      name
      slug
      view
    }
  }
`;

export const GET_PRODUCTS_AUTOCOMPLETE = gql`
  query v1_general_trips($prm: prmForTripsAutocomplete) {
    v1_general_trips(prm: $prm) {
      id
      name
      slug
      city_id
      city_id2
      city_id3
      city_id4
      city_id5
      city_id6
      activity_id
      activity2_id
      activity3_id
      activity4_id
      disabled
      disable_booking
      is_disabled_online
      is_disabled_ts
      partner_id
    }
  }
`;

export const CREATE_LINK = gql`
  mutation v1_affiliates_updateStats($prm: v1_affiliates_updateStats_prm!) {
    v1_affiliates_updateStats(prm: $prm) {
      success
    }
  }
`;
