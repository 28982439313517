import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { IBillVendor, IInitialState } from './interfaces';
import { IAffiliateMember, IAffiliateMemberBilling } from './interfaces';
import { getPhoneUSA } from 'src/helpers/getFormatPhoneNumber';

const initialState: IInitialState = {
  memberData: {
    id: '',
    billing: {
      address: '',
      country: '',
      city: '',
      state: '',
      zip: '',
    },
    firstName: '',
    lastName: '',
    phone: '',
    userName: '',
    website: '',
    company: '',
    email: '',
    email_paypal: '',
    fein_or_snn: '',
    business_type: '',
    vendor_id: null,
    vendor_bank_account_id: null,
    statusWL: null,
    whiteLabel: null,
  },
  billVendorData: {
    name: '',
    shortName: '',
    nameOnCheck: '',
    companyName: '',
    address1: '',
    email: '',
    phone: '',
    addressCity: '',
    addressState: '',
    addressCountry: '',
    addressZip: '',
    accountNumber: '',
    routingNumber: '',
  },
  isBillDataChanged: false,
  receivedData: null,
  memberValidation: {
    phone: true,
    firstName: false,
    lastName: false,
  },
  showError: false,
  showVendorError: false,
  showWLError: false,
  showVendorWarning: undefined,
  showWLPages: false,
  isCreate: true,
  loading: true,
};

const getBillVendorDataByAffiliate = (payload: Partial<IAffiliateMember>) => {
  const billVendorData: IBillVendor = {
    name: `${payload.firstName} ${payload.lastName}`,
    shortName: payload.userName || '',
    email: payload.email || '',
    phone: payload.phone || '',
    nameOnCheck: `${payload.firstName} ${payload.lastName}`,
    companyName: payload.company || '',
    address1: payload.billing?.address || '',
    addressCity: payload.billing?.city || '',
    addressCountry: payload.billing?.country || '',
    addressState: payload.billing?.state || '',
    addressZip: payload.billing?.zip || '',
    accountNumber: '',
    routingNumber: '',
  };

  return billVendorData;
};

export const affiliateMemberSlice = createSlice({
  name: 'affiliateMember',
  initialState,
  reducers: {
    setInitialData: (
      state,
      action: PayloadAction<Partial<IAffiliateMember>>
    ) => {
      const { payload } = action;

      if (!payload.vendor_id) {
        const billVendorData = getBillVendorDataByAffiliate(payload);

        state.billVendorData = {
          ...state.billVendorData,
          ...billVendorData,
        };
      }

      state.memberData = {
        ...state.memberData,
        ...payload,
      };

      state.receivedData = {
        ...state.memberData,
        ...payload,
      };
    },
    setMemberData: (
      state,
      action: PayloadAction<Partial<IAffiliateMember>>
    ) => {
      const { payload } = action;

      const keys = Object.keys(payload);

      keys.forEach((key) => {
        if (key === 'phone') {
          state.memberValidation.phone = payload[key]
            ? isValidPhoneNumber(getPhoneUSA(payload[key]))
            : true;
        }
        if (key === 'firstName' || key === 'lastName') {
          state.memberValidation[key] = !!payload[key];
        }
      });

      state.memberData = {
        ...state.memberData,
        ...payload,
      };

      const billVendorData = getBillVendorDataByAffiliate(state.memberData);

      state.billVendorData = {
        ...state.billVendorData,
        ...billVendorData,
        accountNumber: state.billVendorData.accountNumber,
        routingNumber: state.billVendorData.routingNumber,
      };
    },
    setMemberBillingData: (
      state,
      action: PayloadAction<Partial<IAffiliateMemberBilling>>
    ) => {
      const { payload } = action;

      state.memberData.billing = state.memberData.billing
        ? {
            ...state.memberData.billing,
            ...payload,
          }
        : {
            address: '',
            city: '',
            country: '',
            state: '',
            zip: '',
            ...payload,
          };

      const billVendorData = getBillVendorDataByAffiliate(state.memberData);

      state.billVendorData = {
        ...state.billVendorData,
        ...billVendorData,
        accountNumber: state.billVendorData.accountNumber,
        routingNumber: state.billVendorData.routingNumber,
      };
    },
    setBillVendorData: (state, action: PayloadAction<Partial<IBillVendor>>) => {
      const { payload } = action;

      state.billVendorData = {
        ...state.billVendorData,
        ...payload,
      };
    },
    setChangedBillData: (state, action: PayloadAction<boolean>) => {
      state.isBillDataChanged = action.payload;
    },
    setShowError: (state) => {
      state.showError = true;
    },
    setShowVendorError: (state) => {
      state.showVendorError = true;
    },
    setShowWLError: (state) => {
      state.showWLError = true;
    },
    setShowWLPages: (state, action: PayloadAction<boolean>) => {
      state.showWLPages = action.payload;
    },
    setShowVendorWarning: (state, action: PayloadAction<boolean>) => {
      state.showVendorWarning = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInitialValue: (state) => {
      state.memberData = initialState.memberData;
      state.receivedData = initialState.receivedData;
      state.memberValidation = initialState.memberValidation;
      state.showError = initialState.showError;
      state.showVendorError = initialState.showVendorError;
      state.showWLPages = initialState.showWLPages;
      state.isCreate = initialState.isCreate;
      state.loading = initialState.loading;
    },
  },
});

export const {
  setMemberData,
  setShowError,
  setLoading,
  setInitialValue,
  setMemberBillingData,
  setBillVendorData,
  setInitialData,
  setChangedBillData,
  setShowVendorError,
  setShowVendorWarning,
  setShowWLError,
  setShowWLPages,
} = affiliateMemberSlice.actions;

export const affiliateMember = (state: RootState) => state.affiliateMember;
export const isAffiliateMemberValid = (state: RootState) => {
  const { firstName, lastName, phone } = state.affiliateMember.memberValidation;

  return (
    (phone ||
      state.affiliateMember.memberData.phone ===
        state.affiliateMember.receivedData?.phone) &&
    (firstName ||
      state.affiliateMember.memberData.firstName ===
        state.affiliateMember.receivedData?.firstName) &&
    (lastName ||
      state.affiliateMember.memberData.lastName ===
        state.affiliateMember.receivedData?.lastName)
  );
};
export const isAffiliateMemberValidForVendor = (state: RootState) => {
  const { firstName, lastName, phone, website, fein_or_snn, billing } =
    state.affiliateMember.memberData;
  const { accountNumber, routingNumber, nameOnCheck, name } =
    state.affiliateMember.billVendorData;

  if (
    firstName &&
    lastName &&
    phone &&
    website &&
    fein_or_snn &&
    billing?.address &&
    billing?.city &&
    billing?.country &&
    billing?.state &&
    billing?.zip &&
    accountNumber &&
    accountNumber.length >= 4 &&
    accountNumber.length <= 17 &&
    routingNumber &&
    routingNumber.length === 9 &&
    name &&
    nameOnCheck
  ) {
    return true;
  }

  return false;
};

export const feinOrSnnIsValid = (state: RootState) => {
  const { fein_or_snn } = state.affiliateMember.memberData;
  const feinReg = /^[1-9]\d?-\d{7}$/;
  const ssnReg = /^\d{3}-\d{2}-\d{4}$/;

  if (!fein_or_snn) {
    return true;
  }

  if (feinReg.test(fein_or_snn) || ssnReg.test(fein_or_snn)) {
    return true;
  }

  return false;
};

export const showVendorWarning = (state: RootState) =>
  state.affiliateMember.showVendorWarning;

export const showWLPages = (state: RootState) =>
  state.affiliateMember.showWLPages;

export const isAffiliateMemberValidForWL = (state: RootState) => {
  const { website, whiteLabel } = state.affiliateMember.memberData;
  const WLregex = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/;
  if (website && whiteLabel && WLregex.test(whiteLabel)) {
    return true;
  }
};

export default affiliateMemberSlice.reducer;
