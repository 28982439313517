import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 45px)',
    width: '100%',
    paddingTop: '45px',
  },
}));
