import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'src/redux/store';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { setNotification } from '../notifications/sliceNotifications';
import { AffiliateIdMap } from 'src/helpers/usersIdMap';
import { routerActions } from 'connected-react-router';

export interface IForgotPassword {
  email: string;
}

export interface IConfirmPassword {
  email: string;
  code: string;
  password: string;
}

export interface IChangePassword {
  user: string;
  oldPassword: string;
  newPassword: string;
}

export interface IAuthConfirm {
  username: string;
  code: string;
}

export interface IAuth {
  email: string;
  password: string;
}

export interface IRegister {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  company: string;
  website: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zip: string;
  fein_or_ssn: string;
  business_type: string;
}

export interface AuthState {
  user: any;
  temporaryUser: any;
  loadingUser: boolean;
  successAuth: boolean;
  resetPasswordEmail: null | string;
  adminLoginError?: boolean;
  adminLoginSuccess?: boolean;
}

const initialState: AuthState = {
  user: null,
  temporaryUser: null,
  loadingUser: true,
  successAuth: false,
  resetPasswordEmail: null,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAffUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.loadingUser = false;
    },
    setWLUser: (state, action: PayloadAction<string>) => {
      state.user = {
        ...state.user,
        whiteLabel: action.payload,
      };
    },
    setTemporaryUser: (state, action: PayloadAction<any>) => {
      state.temporaryUser = action.payload;
    },
    setSuccessAuth: (state, action: PayloadAction<boolean>) => {
      state.successAuth = action.payload;
    },
    setResetPasswordEmail: (state, action: PayloadAction<string>) => {
      state.resetPasswordEmail = action.payload;
    },
    setAdminLoginStatus: (
      state,
      action: PayloadAction<{ success: boolean; error: boolean }>
    ) => {
      const { success, error } = action.payload;
      if (success) {
        state.adminLoginSuccess = true;
      }
      if (error) {
        state.adminLoginError = true;
      }
    },
  },
});

export const {
  setSuccessAuth,
  setCurrentUser,
  setResetPasswordEmail,
  setTemporaryUser,
  setAdminLoginStatus,
  setAffUser,
  setWLUser,
} = authenticationSlice.actions;

export const getCurrentUser =
  (callback: (prm: any) => any): AppThunk =>
  async (dispatch) => {
    try {
      const data = await Promise.all([
        Auth.currentAuthenticatedUser(),
        Auth.currentUserInfo(),
      ]);

      const user = data[0];

      if (user?.username === 'admin') {
        const aff_id = window.sessionStorage.getItem('affiliate_id');
        if (aff_id) {
          const { payload } = user.signInUserSession.idToken;
          dispatch(
            setAffUser({
              ...payload,
            })
          );

          callback({
            variables: {
              prm: {
                id: aff_id,
              },
            },
          });

          return;
        }

        dispatch(setCurrentUser(null));
        return;
      }

      const userId = user?.username
        ? AffiliateIdMap[user.username] || user.username
        : undefined;

      if (user && userId) {
        const { payload } = user.signInUserSession.idToken;

        dispatch(
          setCurrentUser({
            ...payload,
            id: userId,
          })
        );
      } else {
        dispatch(setCurrentUser(null));
      }
    } catch (error) {
      console.log(error);
      dispatch(routerActions.push('/login'));
      dispatch(setCurrentUser(null));
    }
  };

export const loginFromAdmin =
  (challenge: string, callback: (prm: any) => any): AppThunk =>
  async (dispatch, state) => {
    try {
      const currentUser = state().auth.user;
      if (currentUser) {
        await Auth.signOut();
      }
      const user = await Auth.signIn('admin');
      await Auth.sendCustomChallengeAnswer(user, challenge);
      const userPrms = await Auth.currentSession();
      if (userPrms) {
        const { payload } = userPrms.getIdToken();
        if (payload['custom:aff_id']) {
          window.sessionStorage.setItem(
            'affiliate_id',
            payload['custom:aff_id']
          );
          dispatch(getCurrentUser(callback));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(setAdminLoginStatus({ success: false, error: true }));
    }
  };

export const getCurrentSession = (): AppThunk => async () => {
  try {
    const token = await Auth.currentSession();
    return token;
  } catch (error) {
    console.log(error);
  }
};

export const signUp =
  (
    {
      email,
      username,
      first_name,
      last_name,
      phone_number,
      password,
      company,
      website,
      country,
      state,
      city,
      address,
      zip,
      fein_or_ssn,
      business_type,
    }: IRegister,
    callback: (success: boolean) => void,
    onFailRegistration: (message: string) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.signUp({
        username: username.trim(),
        password: password.trim(),
        attributes: {
          email: email.trim(),
          given_name: first_name.trim(),
          family_name: last_name.trim(),
          phone_number,
        },
        clientMetadata: {
          company,
          website,
          country,
          state,
          city,
          address,
          zip,
          fein_or_ssn,
          business_type,
        },
      });
      callback(true);
    } catch (error) {
      const message = (error as Error).message.includes(
        'PreSignUp failed with error'
      )
        ? (error as Error).message.split('PreSignUp failed with error')[1]
        : (error as Error).message;
      onFailRegistration(message);
      callback(false);
    }
  };

export const confirmSignUp =
  ({ username, code }: IAuthConfirm): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      dispatch(
        setNotification({
          type: 'error',
          message: (error as Error).message,
        })
      );
    }
  };

export const signIn =
  ({ email, password }: IAuth): AppThunk =>
  async (dispatch) => {
    try {
      const user = await Auth.signIn(email, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return dispatch(
          setTemporaryUser({
            email,
            password,
            userAttributes: user.challengeParam.userAttributes,
          })
        );
      }
      if (!user.signInUserSession) return;
      const { payload } = user.signInUserSession.idToken;
      dispatch(
        setCurrentUser({
          ...payload,
          id: payload['custom:id'],
        })
      );
      dispatch(setSuccessAuth(true));
    } catch (error) {
      dispatch(
        setNotification({ type: 'error', message: (error as Error).message })
      );
    }
  };

export const signInWithGoogle = (): AppThunk => async (dispatch) => {
  try {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  } catch (error) {
    dispatch(
      setNotification({
        type: 'error',
        message: (error as Error).message,
      })
    );
  }
};

export const signInWithFacebook = (): AppThunk => async (dispatch) => {
  try {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  } catch (error) {
    dispatch(
      setNotification({
        type: 'error',
        message: (error as Error).message,
      })
    );
  }
};

export const signOut = (): AppThunk => async (dispatch) => {
  try {
    await Auth.signOut();

    dispatch(setCurrentUser(null));
  } catch (error) {
    dispatch(
      setNotification({
        type: 'error',
        message: (error as Error).message,
      })
    );
  }
};

export const signOutEverywere = (): AppThunk => async (dispatch) => {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    dispatch(
      setNotification({
        type: 'error',
        message: (error as Error).message,
      })
    );
  }
};

export const changePassword =
  ({ user, oldPassword, newPassword }: IChangePassword): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (error) {
      dispatch(
        setNotification({
          type: 'error',
          message: (error as Error).message,
        })
      );
    }
  };

export const forgotPassword =
  ({ email }: IForgotPassword): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.forgotPassword(email);
      dispatch(setResetPasswordEmail(email));
    } catch (error) {
      dispatch(
        setNotification({
          type: 'error',
          message: (error as Error).message,
        })
      );
    }
  };

export const forgotPasswordSubmit =
  ({ email, code, password }: IConfirmPassword): AppThunk =>
  async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      dispatch(setSuccessAuth(true));
    } catch (error) {
      dispatch(
        setNotification({
          type: 'error',
          message: (error as Error).message,
        })
      );
    }
  };

export const completePassword =
  (temporaryUser: any, data: any): AppThunk =>
  async (dispatch) => {
    try {
      const user = await Auth.signIn(
        temporaryUser.email,
        temporaryUser.password
      );

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const dataUser = await Auth.completeNewPassword(user, data.password, {
          email: data.email || user.challengeParam.userAttributes.email,
          given_name:
            data.first_name || user.challengeParam.userAttributes.given_name,
          family_name:
            data.last_name || user.challengeParam.userAttributes.family_name,
          phone_number:
            data.phone_number ||
            user.challengeParam.userAttributes.phone_number,
        });
        if (!dataUser.signInUserSession) return;
        const { payload } = dataUser.signInUserSession.idToken;
        dispatch(
          setCurrentUser({
            ...payload,
          })
        );
        dispatch(setTemporaryUser(null));
        dispatch(
          setNotification({
            type: 'info',
            position: 'external',
            message: 'Welcome in platform',
          })
        );
      }
    } catch (error) {
      console.log(error);
      setNotification({
        type: 'error',
        message: (error as Error).message,
      });
    }
  };

export const currentUser = (state: RootState) => state.auth.user;
export const auth = (state: RootState) => state.auth;
export const selectLoadingUser = (state: RootState) => state.auth.loadingUser;
export const successAuth = (state: RootState) => state.auth.successAuth;
export const resetPasswordEmail = (state: RootState) =>
  state.auth.resetPasswordEmail;
export const temporaryUser = (state: RootState) => state.auth.temporaryUser;

export default authenticationSlice.reducer;
