import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_PARTNERS_AUTOCOMPLETE } from 'src/api/partners';
import { setNotification } from 'src/features/notifications/sliceNotifications';

interface IPartnerAutocomplete {
  v1_partners_autocomplete: IPartner[];
}

export interface IPartner {
  id: number;
  title: string;
  city_id: string;
}

export const usePartnersAutocomplete = (skip: boolean = true) => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery<IPartnerAutocomplete>(
    GET_PARTNERS_AUTOCOMPLETE,
    {
      onError: (error) => {
        dispatch(
          setNotification({
            type: 'error',
            message: error.message,
            duration: 10000,
          })
        );
      },
      fetchPolicy: 'cache-first',
      pollInterval: 1800000,
      variables: {},
      skip,
    }
  );

  return {
    data: data?.v1_partners_autocomplete || [],
    loading,
  };
};
