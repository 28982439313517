import parsePhoneNumber from 'libphonenumber-js';

export const getPhoneUSA = (phone?: string) => {
  if (!phone) return '+1';

  try {
    return parsePhoneNumber(phone || '', 'US')?.number.toString() || '+1';
  } catch (err) {
    return '+1';
  }
}
