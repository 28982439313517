import React from 'react';
import icon from 'src/images/spiner-icon.png';
import { useStyles } from '../styles';

export const Spiner = ({ fixed, rounded }: ISpinerProps) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.wrapper} ${
        fixed ? classes.fixed : classes.relative
      }`}
      style={rounded ? { borderRadius: '15px' } : { borderRadius: 0 }}
      data-test-id={'Spiner'}>
      <div className={classes.loader}>
        <img src={icon} alt="tripshock" />
      </div>
    </div>
  );
};

interface ISpinerProps {
  fixed?: boolean;
  rounded?: boolean;
}
