import { makeStyles, createTheme } from '@material-ui/core/styles';
import { deepOrange, grey } from '@material-ui/core/colors/';
import background from 'src/images/newBg.png';
import backgroundAdaptive from 'src/images/newBgAdaptive.png';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    paddingTop: '65px',
    background: '#feffff',
    '&.home': {
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.down('xs')]: {
        backgroundImage: `url(${backgroundAdaptive})`,
        paddingTop: '70px',
      },
    },
  },
  main: {
    overflowX: 'hidden',
    display: 'flex',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  footer: {
    color: Palette.gray_2,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    justifyContent: 'flex-end',
    '&.home': {
      color: Palette.gray_2,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
  },
  footer_content: {
    margin: '15px 0',
    fontSize: '12px',

  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#5ACC6A',
      light: '#5EE071',
      dark: '#50BF60',
      contrastText: '#fff',
    },
    secondary: grey,
    error: deepOrange,
  },
});
