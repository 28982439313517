import React, { ReactNode } from 'react';
import { ReactComponent as Dashboard } from 'src/assets/sidebar/dashboard.svg';
import { ReactComponent as CityWidget } from 'src/assets/sidebar/city_widget.svg';
import { ReactComponent as ActivityWidget } from 'src/assets/sidebar/activity_widget.svg';
import { ReactComponent as Orders } from 'src/assets/sidebar/orders.svg';
import { ReactComponent as Payouts } from 'src/assets/sidebar/payouts.svg';
import { ReactComponent as Links } from 'src/assets/sidebar/links.svg';
import { ReactComponent as Promotions } from 'src/assets/sidebar/promotions.svg';
import { ReactComponent as NewActivities } from 'src/assets/sidebar/new_activities.svg';
import { ReactComponent as DisabledActivities } from 'src/assets/sidebar/disabled_activities.svg';
import { ReactComponent as Insights } from 'src/assets/sidebar/insights.svg';
import { ReactComponent as FAQ } from 'src/assets/sidebar/faq.svg';
import { ReactComponent as Profile } from 'src/assets/sidebar/profile.svg';
import { ReactComponent as Trips } from 'src/assets/sidebar/trips.svg';
import { ReactComponent as Content } from 'src/assets/sidebar/content.svg';
import { ReactComponent as InfoApp } from 'src/assets/sidebar/info_and_app.svg'; 
import {
  PROFILE_PATH,
  DASHBOARD_PATH,
  COMMISSIONS_PATH,
  FAQ_PATH,
  PAYOUT_PATH,
  LINKS_PATH,
  DEALS_PROMOTIONS_PATH,
  NEW_ACTIVITIES_PATH,
  TOP_ATTRACTIONS_PATH,
  CITY_WIDGET_PATH,
  ACTIVITY_WIDGET_PATH,
  DISABLED_TRIPS_PATH,
  WL_TRIPS_PATH,
  WL_CONTENT_PATH,
  WL_INFO_APP_PATH,
} from 'src/routes/config/paths';

const tools =
  process.env.REACT_APP_ENV !== 'prod'
    ? [
        {
          title: 'Tools',
          links: [
            {
              title: 'City widget',
              path: CITY_WIDGET_PATH,
              icon: <CityWidget />,
            },
            {
              title: 'Activity widget',
              path: ACTIVITY_WIDGET_PATH,
              icon: <ActivityWidget />,
            },
          ],
        },
      ]
    : [];

export const config: Navigation[] = [
  {
    title: 'Affiliate Member',
    links: [
      {
        title: 'Dashboard',
        path: DASHBOARD_PATH,
        icon: <Dashboard />,
      },
      {
        title: 'Orders',
        path: COMMISSIONS_PATH,
        icon: <Orders />,
      },
      {
        title: 'Payouts',
        path: PAYOUT_PATH,
        icon: <Payouts />,
      },
      {
        title: 'Links',
        path: LINKS_PATH,
        icon: <Links />,
      },
    ],
  },
  {
    title: 'Tours & Activities',
    links: [
      {
        title: 'Incentives & Promotions',
        path: DEALS_PROMOTIONS_PATH,
        icon: <Promotions />,
      },
      {
        title: 'New Activities',
        path: NEW_ACTIVITIES_PATH,
        icon: <NewActivities />,
      },
      {
        title: 'Disabled Activities',
        path: DISABLED_TRIPS_PATH,
        icon: <DisabledActivities />,
      },
      {
        title: 'Insights',
        path: TOP_ATTRACTIONS_PATH,
        icon: <Insights />,
      },
    ],
  },
  {
    title: 'White label',
    links: [
      {
        title: 'Trips',
        path: WL_TRIPS_PATH,
        icon: <Trips />,
      },
      {
        title: 'Content',
        path: WL_CONTENT_PATH,
        icon: <Content />,
      },
      {
        title: 'Info & Application',
        path: WL_INFO_APP_PATH,
        icon: <InfoApp />,
      },
    ],
  },
  {
    title: 'Support',
    links: [
      {
        title: 'FAQ',
        path: FAQ_PATH,
        icon: <FAQ />,
      },
    ],
  },
  ...tools,
  {
    title: 'Settings',
    links: [
      {
        title: 'Profile',
        path: PROFILE_PATH,
        icon: <Profile />,
      },
    ],
  },
];

export type NavigationLink = {
  title: string;
  path: string;
  icon: ReactNode;
};

export type Navigation = {
  title: string;
  links: NavigationLink[];
};
