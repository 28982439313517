import { makeStyles } from '@material-ui/core/styles';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    // height: '100vh',
    zIndex: 1003,
    transition: 'all ease 0.4s',
    overflowX: 'hidden',
    border: 'none',
    [theme.breakpoints.up('lg')]: {
      height: '100vh',
    },
    '& .MuiPaper-root': {
      display: 'block',
      width: '100%',
      maxWidth: '260px',
      background: Palette.dark_1,
      transition: 'all ease 0.4s',
      borderRadius: "0 15px 15px 0",
      [theme.breakpoints.up('lg')]: {
        height: '100vh',
      },
    },
  },
  drawerOpen: {
    maxWidth: '260px',
    '& .MuiPaper-root': {
      transform: 'translateX(0)',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
      '& .MuiPaper-root': {
        maxWidth: '260px',
        transform: 'translateX(0)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '0',
    },
  },
  drawerClose: {
    maxWidth: '80px',
    
    '& .MuiPaper-root': {
      maxWidth: '80px',
      borderRadius: "0 6px 6px 0",
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '0',
      '& .MuiPaper-root': {
        maxWidth: '260px',
        transform: 'translateX(-100%)',
      },
    },
  },
  scrollbarWrapper: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '100vh',
    },
    position: 'relative',
  },
}));
