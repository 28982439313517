import { useSelector } from 'react-redux';
import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute, AuthRoute, WlRoute } from './routes';
import {
  PublicRoutes,
  RegistrationRoutes,
  PrivateRoutes,
  SystemRoutes,
  NotFoundRoutes,
  publicPaths,
  privatePaths,
  systemPaths,
  registrationPaths,
  WLRoutes,
  WLRoutesPaths,
} from './config';
import { MainLayout } from 'src/layouts/MainLayout';
import { SystemLayout } from 'src/layouts/SystemLayout';
import { Spiner } from 'src/components/Loaders/';
import HomeLayout from 'src/layouts/HomeLayout';
import { auth } from 'src/features/authentication/sliceAuthentication';
import { ADMIN_LOGIN_PATH } from './config/paths';
import { ADMIN_LOGIN_PAGE } from './config/pages';
import { RegistrationLayout } from 'src/layouts/RegistrationLayout';

const Routes = () => {
  const { user } = useSelector(auth);

  return (
    <Switch>
      <Route path={ADMIN_LOGIN_PATH}>
        <Suspense fallback={<Spiner />}>
          <Route component={ADMIN_LOGIN_PAGE} />
        </Suspense>
      </Route>

      <Route
        path={
          !user
            ? publicPaths
            : publicPaths.filter((p) => !privatePaths.includes(p))
        }
        component={() => (
          <HomeLayout>
            <Suspense fallback={<Spiner fixed />}>
              {PublicRoutes.map((route) => (
                <AuthRoute
                  path={route.path}
                  component={route.component}
                  exact
                  key={route.path}
                />
              ))}
            </Suspense>
          </HomeLayout>
        )}
        exact
      />

      <Route path={registrationPaths} exact>
        <RegistrationLayout>
          <Suspense fallback={<Spiner />}>
            {RegistrationRoutes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </RegistrationLayout>
      </Route>

      <Route path={systemPaths} exact>
        <SystemLayout>
          <Suspense fallback={<Spiner />}>
            {SystemRoutes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </SystemLayout>
      </Route>

      <Route path={[...privatePaths, ...WLRoutesPaths]} exact>
        <MainLayout>
          <Suspense fallback={<Spiner />}>
            {PrivateRoutes.map((route) => (
              <PrivateRoute
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
            {WLRoutes.map((route) => (
              <WlRoute
                path={route.path}
                component={route.component}
                exact
                key={route.path}
              />
            ))}
          </Suspense>
        </MainLayout>
      </Route>

      {/* {isUserWL && (
        <Route path={WLRoutesPaths} exact>
          <MainLayout>
            <Suspense fallback={<Spiner />}>
              {WLRoutes.map((route) => (
                <PrivateRoute
                  path={route.path}
                  component={route.component}
                  exact
                  key={route.path}
                />
              ))}
            </Suspense>
          </MainLayout>
        </Route>
      )} */}

      <SystemLayout>
        <Suspense fallback={<Spiner />}>
          <Route
            path={NotFoundRoutes.path}
            component={NotFoundRoutes.component}
          />
        </Suspense>
      </SystemLayout>
    </Switch>
  );
};

export default Routes;
