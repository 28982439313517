import { makeStyles } from '@material-ui/core/styles';
import { Fonts } from 'src/themes/Fonts';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '0 8px 2px 8px',
    '& .MuiList-padding': {
      paddingTop: '5px',
      paddingBottom: 0,
    },
  },
  linkHeader: {
    margin: '4px 0 8px 0',
    ...Fonts.desktop.body_3.medium,
    padding: '0 8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: Palette.white,
  },
  linkWrapper: {
    display: 'block',
    textDecoration: 'none',
    ...Fonts.desktop.body_4.regular,
    borderRadius: '10px',
    margin: '4px 0',
    color: Palette.gray_3,
    '&:hover': {
      backgroundColor: Palette.dark_3,
      color: Palette.brand,
    },
    '&.active': {
      backgroundColor: Palette.dark_3,
      color: Palette.brand,
    },
  },
  link: {
    minWidth: '60px',
    height: '40px',
    display: 'flex',
    padding: '13px 20px',
    borderRadius: '10px',
    '& svg': {
      '&:hover': {
        fill: Palette.brand,
      },
      '&.active': {
        fill: Palette.brand,
      },
    },
  },
  linkTitle: {
    width: '100%',
    // overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebarDivider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1px',
    background: Palette.gray_2,
    opacity: 0.2,
    marginBottom: '20px',
  },
}));
