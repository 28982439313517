import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Slide } from '@material-ui/core/';
import { Alert } from '@material-ui/lab';
import { removeNotification, INotification } from '../sliceNotifications';
import { useStyles } from './stylesNotification';
import { ReactComponent as Success } from 'src/assets/icons/succesNotification.svg';
import { ReactComponent as ErrorNotification } from 'src/assets/icons/errorNotification.svg';
import { ReactComponent as WarningNotification } from 'src/assets/icons/warningNotification.svg';
import { ReactComponent as InfoNotification } from 'src/assets/icons/infoNotification.svg';
import { ReactComponent as CloseWhiteBtn } from 'src/assets/icons/closeWhite.svg';

export const Notification: React.FC<{ notification: INotification }> = ({
  notification,
}) => {
  const classes = useStyles({ notification });
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => dispatch(removeNotification(notification.key)), 400);
  }, [setOpen, dispatch, notification]);

  useEffect(() => {
    if (notification) {
      setTimeout(() => handleClose(), notification.duration);
    }
  }, [notification, handleClose]);

  if (!notification) {
    return null;
  }

  return (
    <Slide
      in={open}
      direction={notification.position === 'interior' ? 'up' : 'down'}>
      <Alert
        className={classes.alert}
        severity={notification.type}
        iconMapping={{
          error: <ErrorNotification fontSize="inherit" />,
          info: <InfoNotification fontSize="inherit" />,
          success: <Success fontSize="inherit" />,
          warning: <WarningNotification fontSize="inherit" />,
        }}
        action={
          <div className={classes.closeBtn} onClick={handleClose}>
            <CloseWhiteBtn />
          </div>
        }>
        {notification.message}
      </Alert>
    </Slide>
  );
};
