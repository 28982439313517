type AffiliateMap = {
  [key: string]: string;
};

export const AffiliateIdMap: AffiliateMap = {
  '128470': '116',
  '30aescapes': '242',
  '30aprime': '179',
  '30atelevision': '260',
  '30atours': '233',
  adventureswithally: '321',
  adworx: '81',
  allsea: '135',
  alongforthetrip: '202',
  alwaysontheshore: '341',
  amachen: '50',
  amom4u: '223',
  aquavacations: '343',
  assoctvl: '11',
  atlantasfrugalmom: '231',
  bambasbh: '356',
  bayolken: '347',
  beachbux: '257',
  beachceo1: '195',
  beachceo2: '196',
  beachgetaways: '234',
  beachgroup: '208',
  beachguide: '236',
  beachreunion: '39',
  beautifulbeach: '240',
  benjamin77: '182',
  berktechinv: '317',
  bestbeachgetaways: '38',
  bethanywinston: '253',
  beyatli: '219',
  bh9889: '190',
  bigfishrentals: '293',
  blm0303: '268',
  bluemoonvacationrentals: '342',
  bright: '157',
  broadwaynashville: '336',
  brokenbraintravel: '353',
  calimarie: '184',
  carajouglard: '254',
  cnorman: '174',
  coastlinevacations: '313',
  compassresorts: '252',
  condoworld: '237',
  consistentlycurious: '224',
  couponplusdeals: '255',
  cra2014: '94',
  crazyfamilyadventure: '183',
  cruisehive: '203',
  csachse25: '151',
  daronpayne: '155',
  dcorwin: '137',
  destin: '91',
  destinationcoupons: '243',
  destinboardwalk: '7',
  destindirect: '3',
  destindiva: '217',
  destindreamcondo: '289',
  destindreamers: '324',
  destinfishing: '129',
  destinflorida: '272',
  destinonthebeach: '306',
  destinresort: '42',
  destinshines: '47',
  destintravels: '180',
  destinwave: '218',
  destinwest: '246',
  destinwestescapes: '248',
  destinwheels: '194',
  developer2: '292',
  devnullzen: '164',
  discover30a: '209',
  distrodata: '330',
  dmbrowne: '244',
  drenick: '168',
  dukeitt: '205',
  dustywest: '68',
  eglinitt: '312',
  elitebeachrentals: '333',
  elitetravel: '120',
  emeraldcoastkids: '229',
  evan2933: '71',
  evansmokaya: '340',
  everythingbutfish: '329',
  fancy43: '74',
  firsttimevisiting: '169',
  fishiq: '335',
  fivestar: '270',
  frobisher: '76',
  fulltimefamilies: '283',
  funnewjersey: '346',
  fwbchamber: '267',
  glampingwithgrandpa: '282',
  gorkembedir: '359',
  gosouthern: '266',
  grandstrandmarketing: '334',
  gregfisher: '1',
  gregfisher11: '178',
  harbus: '107',
  hccondos: '165',
  holidayisle: '28',
  icecoldtravel: '121',
  ilainvestmentsinc: '344',
  ilovedestin: '299',
  internjamnola: '276',
  jasonsemeyn: '204',
  jerisworldtravel: '331',
  jerryco: '298',
  jessicanturner: '187',
  jetstream: '349',
  jgrundig: '304',
  joytodd: '26',
  judith0106: '85',
  julemegami: '138',
  karonwarren: '221',
  katsol: '8',
  kdc886: '84',
  kenrada1: '43',
  kmyost85: '72',
  kpurviance: '30',
  lauraplantation: '262',
  legacyvacations: '309',
  leslipeterson: '188',
  liatphilipson: '296',
  lifeafterwork: '172',
  livelifeandtravel: '326',
  livvacations: '287',
  lmgallinaro: '310',
  localmomscoop: '269',
  lolanding1: '27',
  luannlam: '273',
  luausandestin: '290',
  lynseykmetz: '325',
  magnificat: '210',
  makenadoris21: '354',
  mariarosepr: '303',
  mfisher: '189',
  milesjd: '143',
  milkwithnosugar: '225',
  modelsunshinellc: '348',
  momendeavors: '302',
  msvgibson73: '148',
  mydestinbeach: '249',
  myguestbook: '158',
  myrtlebeachonthecheap: '279',
  nauticalproperties: '297',
  navarrebeachinsider: '280',
  neonandbeach: '44',
  nestindestin: '295',
  neworleans: '149',
  nickmattison: '170',
  nolaluxuryvacationrentals: '258',
  northshoreparent: '319',
  nsapanamacity: '263',
  orangebeachbreeze: '201',
  orlandokayaktour: '301',
  ourgulfshoresvacation: '316',
  paradisenews1: '228',
  parrisa: '320',
  pelicanbeach: '323',
  planomoms: '238',
  plantationcountry: '261',
  prickettproperties: '256',
  roamilicious: '226',
  ruslan28: '360',
  rvtravel101: '175',
  rwcalvert: '181',
  sagdiformanov: '345',
  sainttropez: '337',
  saltwatergrande: '339',
  sarahsimon: '277',
  saveondestin: '274',
  sbgulfcoast: '70',
  scarletttravel: '327',
  seavichedestin: '288',
  shoredreams: '46',
  skyrunvacationrentals: '332',
  snapsoccer: '245',
  soozhan: '2',
  southbaldwin: '271',
  southerntidemedia: '285',
  stacieinatlanta: '305',
  sumervacationrentals: '291',
  summerplace: '265',
  tambrant: '69',
  tamekiabradford: '250',
  tammileetips: '247',
  testuser3: '200',
  theaccessoryfile: '358',
  thetravelmaid: '134',
  thisismysouth: '227',
  thosecrazynelsons: '230',
  tiffanymatthews: '286',
  tinamark: '156',
  tinyhouse: '351',
  tls2017: '153',
  tonyadenmark: '207',
  tonyaprater: '315',
  travelfishflorida: '192',
  travelincoupons: '352',
  travelinggracevacations: '311',
  tripchat: '264',
  tripdestin: '300',
  tripshock: '216',
  tripshockreservations: '322',
  tyndallitt: '241',
  vacationlife: '294',
  vacationrentalsinkeywest: '338',
  vacationrentalsnmb: '235',
  vacayservices: '308',
  vacayzenvillas: '357',
  ventureoutvacations: '239',
  veravise: '318',
  vhchospitality: '355',
  wandermama: '154',
  wandre: '328',
  we3travel: '222',
  welovemiramarbeach: '278',
  wesman70: '232',
  westendpcb: '281',
  whiskingapples: '251',
  wtravelblog: '350',
  xtremeh20: '307',
  ycolasan: '176',
  ycspeights: '136',
  yodertoterblog: '314',
  youfoundsarah: '284',
  yourwelcome: '206',
  ytravelblog: '193',
  zulabeach: '259',
};
