export const Palette = {
  brand: '#0DD1C5',
  brand_opacity: 'rgba(13, 209, 197, 0.1)',
  brand_opacity_2: 'rgba(13, 209, 197, 0.05)',
  brand_opacity_3: 'rgba(13, 209, 197, 0.15)',
  brand_hover_2: '#f3fdfc',
  brand_hover: '#0DB8AE',
  gradient: 'linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)',
  gradient_hover: 'linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)',
  gradient_2: 'linear-gradient(90deg, #92CFCF 0%, #7AB5AE 100%)',
  new_dark_blue: '#222F3C',
  blue: '#6880FD',
  dark_1: '#202020',
  dark_2: '#1D1E20',
  dark_3: '#2E3035',
  dark_gradient: 'linear-gradient(270deg, #344755 0%, #597A7C 108.89%)',
  ash: '#FAFAFA',
  white: '#FFFFFF',
  gray_gradient: 'linear-gradient(270deg, #ACB9BA 0%, #D1DDDD 108.89%)',
  gray_1: '#333333',
  gray_2: '#6C6B6B',
  gray_3: '#C4C4C4',
  gray_4: '#DDDDDD',
  light_pink: '#FEF6F6',
  faded_mint: '#F9FCFD',
  faded_mint_opacity: '#fafdfd',
  dark_blue: '#697786',
  info: {
    text: '#5C8FDC',
    background: '#E3EFFF',
    border: '#71A5E9',
    close: '#C0D4F2',
  },
  success: {
    text: '#50B061',
    background: '#DFF8E3',
    border: '#65C576',
    close: '#BCEBC4',
  },
  warning: {
    text: '#FF9800',
    background: '#FFEFD6',
    border: '#EBB35A',
    close: '#FFD89D',
  },
  error: {
    text: '#EC4652',
    background: '#FFE5E6',
    border: '#FF9DA2',
    close: '#F8B8BC',
  },
  order_statuses: {
    made: '#76E763',
    on_request: '#53E0FF',
    confirmed: '#0DD1C5',
    no_show: '#A700E1',
    paid: '#4C93FF',
    checked_in: '#0DB8AE',
    completed: '#FF7BDA',
    unconformed: '#FFD643',
    cancelled: '#EC4652',
    pending: '#FF8642',
  },
  payout_statuses: {
    succeeded: '#76E763',
    booked: '#53E0FF',
    invoiced: '#FF7BDA',
    pending: '#CF9BFE',
    paid: '#4C93FF',
    cancelled: '#EC4652',
  },
};
