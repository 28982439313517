import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { ConnectedRouter } from 'connected-react-router';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store, history } from './redux/store';
import { Provider } from 'react-redux';
import awsConfig from './awsConfig';
import config from './config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import './styles/index.scss';

const jwtToken = async () => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  return token;
};

const httpLink = new HttpLink({
  uri: config.apiUrl,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await jwtToken();
    return {
      headers: {
        ...headers,
        authorization: token || null,
      },
    };
  } catch (e) {
    return {
      headers: {
        ...headers,
        'X-Api-Key': config.apiKey,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          v1_activities_data: {
            merge: true,
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

Amplify.configure(awsConfig);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CssBaseline />
        <App />
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
