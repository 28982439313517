import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { IInitialState, IWLData } from './interfaces';
import { validateEmail } from 'src/helpers/BasicValidation';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { getPhoneUSA } from 'src/helpers/getFormatPhoneNumber';

const initialState: IInitialState = {
  wlData: {
    id: '',
    title: '',
    about: '',
    description: '',
    logo: '',
    hero: '',
    subtitle: '',
    faq: '',
    latitude: '',
    longitude: '',
    google_place_id: '',
    address: '',
    google_title: '',
    google_place_api_response: '',
    policy: '',
    whiteLabel: '',
    self: [],
    email: '',
    phone: '',
    name: '',
  },
  receivedData: null,
  wlValidation: {
    title: true,
    email: false,
    phone: false,
    name: false,
  },
  showError: false,
  loading: true,
};

export const wlSlice = createSlice({
  name: 'wl',
  initialState,
  reducers: {
    setInitialData: (state, action: PayloadAction<Partial<IWLData>>) => {
      const { payload } = action;

      state.wlData = {
        ...state.wlData,
        ...payload,
      };

      state.receivedData = {
        ...state.wlData,
        ...payload,
      };
    },
    setWLData: (state, action: PayloadAction<Partial<IWLData>>) => {
      const { payload } = action;

      const keys = Object.keys(payload);

      keys.forEach((key) => {
        if (key === 'email') {
          state.wlValidation.email = validateEmail(payload[key] || '');
        }
        if (key === 'phone') {
          state.wlValidation.phone = payload[key]
            ? isValidPhoneNumber(getPhoneUSA(payload[key]))
            : true;
        }
        if (key === 'name' || key === 'title') {
          state.wlValidation[key] = !!payload[key];
        }
      });

      state.wlData = {
        ...state.wlData,
        ...payload,
      };
    },

    setShowError: (state) => {
      state.showError = true;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInitialValue: (state) => {
      state.wlData = initialState.wlData;
      state.receivedData = initialState.receivedData;
      state.wlValidation = initialState.wlValidation;
      state.showError = initialState.showError;
      state.loading = initialState.loading;
    },
  },
});

export const {
  setShowError,
  setLoading,
  setInitialValue,
  setInitialData,
  setWLData,
} = wlSlice.actions;

export const wl = (state: RootState) => state.wl;
export const wlData = (state: RootState) => state.wl.wlData;
export const receivedData = (state: RootState) => state.wl.receivedData;
export const isWLValid = (state: RootState) => {
  const { email, phone, name, title } = state.wl.wlValidation;
  //const phoneValid = isValidPhoneNumber(state.wl.wlData.phone || '');

  return email && phone && name && title;
};

export default wlSlice.reducer;
