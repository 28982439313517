import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, history } from 'src/redux/store';

type ActiveTab = {
  [key: string]: number;
};

interface IInitialState {
  activeTabs: ActiveTab | null;
  allowedTab: number;
}

const initialState: IInitialState = {
  activeTabs: null,
  allowedTab: 0,
};

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      const { payload } = action;

      state.activeTabs = {
        ...state.activeTabs,
        [history.location.pathname]: payload,
      };
    },
    setAllowedTab: (state, action: PayloadAction<number>) => {
      state.allowedTab = action.payload;
    },
  },
});

export const { setActiveTab, setAllowedTab } = tabsSlice.actions;

export const activeTabs = (state: RootState) => state.tabs.activeTabs;
export const activeTab = createSelector(
  activeTabs,
  (tabs) => {
    if (!tabs) return 0;
    return tabs[history.location.pathname] || 0;
  }
);
export const allowTab = (state: RootState) => state.tabs.allowedTab;

export default tabsSlice.reducer;
