import React, { Dispatch, SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core/';
import { ReactComponent as MenuClose } from 'src/assets/icons/arrowLeft.svg';
import { ReactComponent as FullLogo } from 'src/assets/logo/FullLogo.svg';
import { ReactComponent as Logo } from 'src/assets/logo/Logo.svg';
import { useStyles } from './styles';

interface IMainSidebarHeaderProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MainSidebarHeader = ({
  open,
  setOpen,
}: IMainSidebarHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      {open ? (
        <Box p={1} paddingBottom={0}>
          <Box className={classes.MainSidebarHeaderOpen}>
            <NavLink to="/" className={classes.logo}>
              <FullLogo />
            </NavLink>
            <Box
              onClick={() => setOpen(false)}
              color="white"
              className={classes.MainSidebarHeaderToggler}>
              <MenuClose />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box p={1} paddingBottom={0}>
          <Box className={classes.MainSidebarHeaderClose}>
            <NavLink to="/" className={classes.logo}>
              <Logo />
            </NavLink>
          </Box>
        </Box>
      )}
    </>
  );
};
