import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Card, Box, CardContent } from '@material-ui/core/';
import { useStyles, theme } from './styles';

export const SystemLayout: React.FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};
