import { Box } from '@material-ui/core';
import React, { FC, ReactElement, Suspense } from 'react';
import { AuthHeader } from 'src/components/AuthHeader';
import { Spiner } from 'src/components/Loaders';
import { RegistrationProvider } from 'src/contexts/registration';
import { useStyles } from './styles';

export const RegistrationLayout: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <RegistrationProvider>
      <div className={classes.root}>
        <Suspense fallback={<Spiner fixed />}>
          <Box className={classes.root}>
            <AuthHeader />

            {children}
          </Box>
        </Suspense>
      </div>
    </RegistrationProvider>
  );
};
