import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
  },
  page_container: {
    display: 'flex',
    flex: 1,
  },
  content_container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
  },
  page_content_container: {
    height: 'calc(100vh - 70px)',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 50px)',
    },
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1002,
    background: 'rgba(0, 0, 0, 0.3)',
  },
}));
