import {
  HOME_PATH,
  LOGIN_PATH,
  REGISTRATION_PATH,
  NOT_FOUND_PATH,
  FORGOT_PASSWORD_PATH,
  CONFIRM_PASSWORD_PATH,
  UNAUTHORIZED_PATH,
  INTERNAL_SERVER_PATH,
  COMMISSIONS_PATH,
  PROFILE_PATH,
  DASHBOARD_PATH,
  FAQ_PATH,
  PAYOUT_PATH,
  LINKS_PATH,
  DEALS_PROMOTIONS_PATH,
  NEW_ACTIVITIES_PATH,
  TOP_ATTRACTIONS_PATH,
  TOP_ATTRACTIONS_DETAILS_PATH,
  DISABLED_TRIPS_PATH,
  CITY_WIDGET_PATH,
  ACTIVITY_WIDGET_PATH,
  WL_TRIPS_PATH,
  WL_CONTENT_PATH,
  WL_INFO_APP_PATH,
} from './paths';
import {
  HOME_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  NOT_FOUND_PAGE,
  FORGOT_PASSWORD_PAGE,
  CONFIRM_PASSWORD_PAGE,
  UNAUTHORIZED_PAGE,
  INTERNAL_SERVER_PAGE,
  COMMISSIONS_PAGE,
  PROFILE_PAGE,
  DASHBOARD_PAGE,
  FAQ_PAGE,
  PAYOUT_PAGE,
  LINKS_PAGE,
  DEALS_PROMOTIONS_PAGE,
  NEW_ACTIVITIES_PAGE,
  TOP_ATTRACTIONS_PAGE,
  TOP_ATTRACTIONS_DETAILS_PAGE,
  DISABLED_TRIPS_PAGE,
  CITY_WIDGET_PAGE,
  ACTIVITY_WIDGET_PAGE,
  WL_TRIPS_PAGE,
  WL_CONTENT_PAGE,
  WL_INFO_APP_PAGE,
} from './pages';

const widgetRoutes =
  process.env.REACT_APP_ENV !== 'prod'
    ? [
      {
        path: CITY_WIDGET_PATH,
        component: CITY_WIDGET_PAGE,
      },
      {
        path: ACTIVITY_WIDGET_PATH,
        component: ACTIVITY_WIDGET_PAGE,
      },
    ]
    : [];

export const WLRoutes = [
  {
    path: WL_TRIPS_PATH,
    component: WL_TRIPS_PAGE
  },
  {
    path: WL_CONTENT_PATH,
    component: WL_CONTENT_PAGE
  },
];

export const WLRoutesPaths = WLRoutes.map((i) => i.path);

export const PublicRoutes = [
  {
    path: FAQ_PATH,
    component: FAQ_PAGE,
  },
  {
    path: LOGIN_PATH,
    component: LOGIN_PAGE,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: FORGOT_PASSWORD_PAGE,
  },
  {
    path: CONFIRM_PASSWORD_PATH,
    component: CONFIRM_PASSWORD_PAGE,
  },
  {
    path: HOME_PATH,
    component: HOME_PAGE,
  },
];

export const publicPaths = PublicRoutes.map((i) => i.path);

export const RegistrationRoutes = [
  {
    path: REGISTRATION_PATH,
    component: REGISTRATION_PAGE,
  },
];

export const registrationPaths = RegistrationRoutes.map((i) => i.path);

export const PrivateRoutes = [
  {
    path: DASHBOARD_PATH,
    component: DASHBOARD_PAGE,
  },
  {
    path: COMMISSIONS_PATH,
    component: COMMISSIONS_PAGE,
  },
  {
    path: PROFILE_PATH,
    component: PROFILE_PAGE,
  },
  {
    path: FAQ_PATH,
    component: FAQ_PAGE,
  },
  {
    path: PAYOUT_PATH,
    component: PAYOUT_PAGE,
  },
  {
    path: LINKS_PATH,
    component: LINKS_PAGE,
  },
  {
    path: DEALS_PROMOTIONS_PATH,
    component: DEALS_PROMOTIONS_PAGE,
  },
  {
    path: NEW_ACTIVITIES_PATH,
    component: NEW_ACTIVITIES_PAGE,
  },
  {
    path: TOP_ATTRACTIONS_PATH,
    component: TOP_ATTRACTIONS_PAGE,
  },
  {
    path: TOP_ATTRACTIONS_DETAILS_PATH,
    component: TOP_ATTRACTIONS_DETAILS_PAGE,
  },
  {
    path: DISABLED_TRIPS_PATH,
    component: DISABLED_TRIPS_PAGE,
  },

  {
    path: WL_INFO_APP_PATH,
    component: WL_INFO_APP_PAGE
  },
  ...widgetRoutes,
];

export const privatePaths = PrivateRoutes.map((i) => i.path);

export const SystemRoutes = [
  {
    path: UNAUTHORIZED_PATH,
    component: UNAUTHORIZED_PAGE,
  },
  {
    path: INTERNAL_SERVER_PATH,
    component: INTERNAL_SERVER_PAGE,
  },
];

export const systemPaths = SystemRoutes.map((i) => i.path);

export const NotFoundRoutes = {
  path: NOT_FOUND_PATH,
  component: NOT_FOUND_PAGE,
};
