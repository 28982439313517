import { lazy } from 'react';

const LazyPage = (path: string) => {
  const Component = lazy(() => import(`src/pages/${path}`));
  return Component;
};

export const HOME_PAGE = LazyPage('HomePage/HomePage');
export const FAQ_PAGE = LazyPage('FAQ/FAQ');
export const LOGIN_PAGE = LazyPage('Authentication/Login');
export const ADMIN_LOGIN_PAGE = LazyPage('Authentication/AdminLogin');
export const REGISTRATION_PAGE = LazyPage('Authentication/Registration');
export const FORGOT_PASSWORD_PAGE = LazyPage('Authentication/ForgotPassword');
export const CONFIRM_PASSWORD_PAGE = LazyPage('Authentication/ConfirmPassword');
export const NOT_FOUND_PAGE = LazyPage('SystemPages/NotFoundPage');
export const INTERNAL_SERVER_PAGE = LazyPage('SystemPages/InternalServerPage');
export const UNAUTHORIZED_PAGE = LazyPage('SystemPages/UnauthorizedPage');
export const DASHBOARD_PAGE = LazyPage('Dashboard/Dashboard');
export const COMMISSIONS_PAGE = LazyPage('Commissions/Commissions');
export const PROFILE_PAGE = LazyPage('Profile/Profile');
export const PAYOUT_PAGE = LazyPage('Payout/Payout');
export const LINKS_PAGE = LazyPage('Links/Links');
export const DEALS_PROMOTIONS_PAGE = LazyPage(
  'DealsPromotions/DealsPromotions'
);
export const NEW_ACTIVITIES_PAGE = LazyPage('NewActivities/NewActivities');
export const TOP_ATTRACTIONS_PAGE = LazyPage('TopAttractions/TopAttractions');
export const TOP_ATTRACTIONS_DETAILS_PAGE = LazyPage(
  'TopAttractions/TopAttractionsDetails'
);
export const DISABLED_TRIPS_PAGE = LazyPage('DisabledTrips/DisabledTrips');
export const CITY_WIDGET_PAGE = LazyPage('Widgets/CityWidget');
export const ACTIVITY_WIDGET_PAGE = LazyPage('Widgets/ActivityWidget');

export const WL_CONTENT_PAGE = LazyPage('WLContent/WLContent');
export const WL_TRIPS_PAGE = LazyPage('WLTrips/WLTrips');
export const WL_INFO_APP_PAGE = LazyPage('WLInfoAndApp/WLInfoAndApp');