import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  MainSidebarHeaderOpen: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    paddingBottom: '8px',
  },
  MainSidebarHeaderClose: {
    transition: 'all ease 0.3s',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      transition: 'all ease 0.3s',
      display: 'block',
      maxWidth: '46px',
    },
  },
  MainSidebarHeaderToggler: {
    cursor: 'pointer',
  },
  logo: {
    display: 'block',
    textDecoration: 'none',
    '& img': {
      display: 'block',
    },
  },
}));
