import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './styles';
import { Container, Typography } from '@material-ui/core/';
import { AuthHeader } from 'src/components/AuthHeader';
import { useStyles } from './styles';

const HomeLayout: React.FC<{}> = ({ children }) => {
  const classes = useStyles();

  const root =
    window.location.pathname === '/' ? `${classes.root} home` : classes.root;

  const footer =
    window.location.pathname === '/'
      ? `${classes.footer} home`
      : classes.footer;

  return (
    <ThemeProvider theme={theme}>
      <div className={root}>
        <AuthHeader />
        <div className={classes.main}>{children}</div>
        <div>
          <Container>
            <div className={footer}>
              <Typography className={classes.footer_content}>
                © {new Date().getFullYear()} Tripshock
              </Typography>
            </div>
          </Container>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default HomeLayout;
