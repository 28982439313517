export const Fonts = {
  desktop: {
    h1: {
      fontWeight: 600,
      fontSize: '38px',
      lineHeight: '44px',
    },

    h2: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '36px',
    },

    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '30px',
    },

    h4: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '26px',
    },

    body_1: {
      semibold: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px',
      },

      medium: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '24px',
      },

      regular: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    body_2: {
      semibold: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
      },
      medium: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '22px',
      },

      regular: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    body_3: {
      semibold: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
      },

      medium: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
      },

      regular: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    body_4: {
      semibold: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
      },

      medium: {
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
      },

      regular: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
  },
  mobile: {
    h1: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '30px',
    },

    h2: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '26px',
    },

    h3: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
    },

    h4: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
    },

    body_1: {
      semibold: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
      },

      medium: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
      },

      regular: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    body_2: {
      semibold: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
      },

      medium: {
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
      },

      regular: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
  },
};
