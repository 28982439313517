import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_AFFILIATE_STATS } from 'src/api/dashboard';
import { setNotification } from 'src/features/notifications/sliceNotifications';
import {
  IDashboardStatistic,
  IStats,
  IStatsPrm,
} from 'src/pages/Dashboard/interfaces';

export const useAffiliatesStats = (prm: IStatsPrm) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IDashboardStatistic>({});

  const { loading, refetch } = useQuery<IStats, { prm: IStatsPrm }>(
    GET_AFFILIATE_STATS,
    {
      onCompleted: (receivedData) => {
        setData(JSON.parse(receivedData.v1_affiliates_getStats));
      },
      onError: (error) => {
        dispatch(
          setNotification({
            type: 'error',
            message: error.message,
            duration: 10000,
          })
        );
      },
      pollInterval: 1800000,
      variables: {
        prm,
      },
    }
  );

  return {
    data,
    loading,
    refetch,
  };
};
