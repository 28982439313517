import { gql } from '@apollo/client';

export const GET_PAYOUTS = gql`
  query v1_affiliates_readPaymentList(
    $prm: v1_affiliates_readPaymentList_prm!
  ) {
    v1_affiliates_readPaymentList(prm: $prm) {
      items {
        id
        amount
        paidAt
        status
        userId
      }
      pager
    }
  }
`;

export const GET_LAST_PAYOUT = gql`
  query v1_affiliates_readPaymentList(
    $prm: v1_affiliates_readPaymentList_prm!
  ) {
    v1_affiliates_readPaymentList(prm: $prm) {
      items {
        amount
        paidAt
        status
      }

    }
  }
`;
