import { makeStyles } from '@material-ui/core/styles';
import { Fonts } from 'src/themes/Fonts';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '0 20px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '66px',
    zIndex: 1000,
    alignItems: 'center',
    backgroundColor: Palette.white,
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.15)',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
      height: '50px',
    },
  },
  title: {
    color: Palette.gray_1,
    ...Fonts.desktop.body_2.medium,
    [theme.breakpoints.down('xs')]: {
      ...Fonts.mobile.body_2.medium,
    },
  },
  sub_title: {
    display: 'block',
    ...Fonts.desktop.body_3.regular,
    color: Palette.gray_2,
    [theme.breakpoints.down('xs')]: {
      ...Fonts.mobile.body_2.regular,
    },
  },
  menu_container: {
    marginRight: '23px',
    cursor: 'pointer',
    paddingRight: '24px',
    borderRight: `1px solid ${Palette.gray_4}`,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      borderRight: 'none',
      marginRight: '14px',
    },
  },
  menu_box: {
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    background: Palette.white,
    boxShadow: '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
    borderRadius: '15px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '30px',
      height: '30px',
      borderRadius: '10px',
      '& svg': {
        height: '14px'
      }
    },
  },
  left_container: {
    height: '43px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '30px',
    },
  },
  exitApp: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '24px',
    marginLeft: '24px',
    height: '43px',
    borderLeft: `1px solid ${Palette.gray_4}`,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '14px',
      marginLeft: '16px',
      height: '30px',
      '& svg': {
        height: '18px'
      }
    },
  },
}));
