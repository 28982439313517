import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_AUTOCOMPLETE } from 'src/api/profile';
import { setNotification } from 'src/features/notifications/sliceNotifications';

export interface ITrip {
  id: number;
  slug: string;
  name: string;
  city_id: number;
  city_id2: number;
  city_id3: number;
  city_id4: number;
  city_id5: number;
  city_id6: number;
  activity_id: number;
  activity2_id: number;
  activity3_id: number;
  activity4_id: number;
  partner_id: number;
}

interface IProductsAutocomplete {
  v1_general_trips: ITrip[];
}

export const useProductsAutocomplete = (
  skip: boolean = true,
  all: boolean = false
) => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery<IProductsAutocomplete>(
    GET_PRODUCTS_AUTOCOMPLETE,
    {
      onError: (error) => {
        dispatch(
          setNotification({
            type: 'error',
            message: error.message,
            duration: 10000,
          })
        );
      },
      fetchPolicy: 'cache-first',
      pollInterval: 1800000,
      variables: {
        prm: {
          all,
        },
      },
      skip,
    }
  );

  return {
    data: data?.v1_general_trips || [],
    loading,
  };
};
