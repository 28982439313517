import { makeStyles } from '@material-ui/core/styles';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles(() => ({
  wrapperLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
    height: '40px',
    '& img': {
      height: '90px',
    },
  },
  wrapper: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.3)',
    marginBottom: '20px',
    height: '100%',
  },
  fixed: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    height: '100vh',
  },
  relative: {
    position: 'absolute',
    top: '0px',
    right: '0',
    bottom: '0',
    left: '0',
  },
  loader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px',
    width: '130px',
    height: '130px',
    borderRadius: '100%',
    '& img': {
      height: '52px',
      display: 'block',
    },
    '&:before, &:after': {
      content: '""',
      position: 'absolute',

      width: '100%',
      height: '100%',
      borderRadius: '100%',
      border: '10px solid transparent',
      borderTopColor: Palette.brand,
    },
    '&:before': {
      zIndex: 100,
      animation: '$spin 1s infinite',
    },
    '&:after': {
      border: '10px solid #cff6f3',
    },
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
