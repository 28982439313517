import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import authentication from 'src/features/authentication/sliceAuthentication';
import notifications from 'src/features/notifications/sliceNotifications';
import tables from 'src/features/table/sliceTables';
import tabs from 'src/features/tabs/sliceTabs';
import affiliateMember from 'src/redux/AffiliateMember/sliceMembers';
import wl from 'src/redux/WL/sliceWL';

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: {
    auth: authentication,
    notifications,
    tables,
    tabs,
    affiliateMember,
    wl,
    router: connectRouter(history),
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
