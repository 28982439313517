import { gql } from '@apollo/client';

export const GET_AFFILIATE_MEMBER = gql`
  query v1_affiliates_readMember($prm: v1_affiliates_readMember_prm!) {
    v1_affiliates_readMember(prm: $prm) {
      billing {
        zip
        address
        city
        country
        state
      }
      company
      email
      email_paypal
      fein_or_snn
      firstName
      id
      lastName
      phone
      userName
      website
      status
      business_type
      vendor_id
      vendor_bank_account_id
      statusWL
      whiteLabel
      updateEmail
    }
  }
`;

export const GET_AFFILIATE_MEMBER_VENDOR = gql`
  query v1_affiliates_readMember($prm: v1_affiliates_readMember_prm!) {
    v1_affiliates_readMember(prm: $prm) {
      vendor_id
      vendor_bank_account_id
      statusWL
      whiteLabel
    }
  }
`;

export const UPDATE_AFFILIATE_MEMBER = gql`
  mutation v1_affiliates_updateMember($prm: v1_affiliates_operMember_prm!) {
    v1_affiliates_updateMember(prm: $prm) {
      billing {
        zip
        address
        city
        country
        state
      }
      clearing
      company
      createdAt
      email
      email_paypal
      fein_or_snn
      firstName
      id
      incomplete
      lastName
      paidAt
      percent
      phone
      redeemable
      status
      updatedAt
      userName
      visitedAt
      website
      vendor_id
      vendor_bank_account_id
      statusWL
      whiteLabel
    }
  }
`;

export const UPDATE_LINK = gql`
  mutation v1_affiliates_updateStats($prm: v1_affiliates_updateStats_prm!) {
    v1_affiliates_updateStats(prm: $prm) {
      success
    }
  }
`;

export const UPDATE_AFFILIATE_EMAIL = gql`
  mutation v1_affiliates_updateMemberEmail(
    $prm: v1_affiliates_updateMemberEmail_prm!
  ) {
    v1_affiliates_updateMemberEmail(prm: $prm)
  }
`;
