const config = {
  apiUrl:
    'https://3q4t3yw5hnfb5kpcrnped5n2dy.appsync-api.us-east-1.amazonaws.com/graphql',
  tsApiUrl: 'https://api.tripshock.com/dev_v1',
  apiKey: 'da2-x4cidbuubvbcblqf3q25uiclp4',
  userPoolId: 'us-east-1_0c5wp5tRI',
  userPoolWebClientId: '7thngchvltdt7cdbmmdt1j0ofe',
  siteUrl: 'https://staging-front-end.tripshock.com',
  imgUrl: 'https://staging-images.tripshock.com',
  widgetUrl: 'https://widget-staging.tripshock.com/index.min.js',
  imgTitle: 'staging-images.tripshock.com',
  WLSvg: 'https://api.tripshock.com/image-staging/common/svg',
  revaliadateWLClaster: 'ts-staging-front-end',
  revaliadateWLService: 'ts_white_label_staging',
  revaliadateWLKey: 'c06cc1c3-061c-423f-8522-53ece59faff7',
};

if (process.env.REACT_APP_ENV === 'staging') {
  config.apiUrl =
    'https://76t7syjv7nagzcofubygf7v36y.appsync-api.us-east-1.amazonaws.com/graphql';
  config.apiKey = 'da2-3oqqncunivauxfphiqn7qaazji';
}

if (process.env.REACT_APP_ENV === 'prod') {
  config.apiUrl =
    'https://x4umnvav4ff4rb6ewwcsg5nwjy.appsync-api.us-east-1.amazonaws.com/graphql';
  config.tsApiUrl = 'https://api.tripshock.com/v1';
  config.apiKey = 'da2-lqh2t3cpfrflnb7wrohkxm7y6e';
  config.userPoolId = 'us-east-1_9toWY0Eo9';
  config.userPoolWebClientId = '1p6lbc1nipbanffqfqi9cjn6nr';
  config.siteUrl = 'https://tripshock.com';
  config.imgUrl = 'https://images.tripshock.com';
  config.widgetUrl = 'https://widget.tripshock.com/index.min.js';
  config.imgTitle = 'images.tripshock.com';
  config.WLSvg = 'https://api.tripshock.com/image/common/svg';
  config.revaliadateWLClaster = 'ts-prod-front-end';
  config.revaliadateWLService = 'ts_white_label_prod';
  config.revaliadateWLKey = '8fddb567-2a46-4590-b9d7-54644d30af31';
}

export default config;
