import React, { Dispatch, SetStateAction } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { MainSidebarHeader } from './MainSidebarHeader';
import { MainSidebarNavigation } from './MainSidebarNavigation';

interface IMainSidebarProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MainSidebar = ({ open, setOpen }: IMainSidebarProps) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.root, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}>
      <Box className={classes.scrollbarWrapper}>
        <PerfectScrollbar>
          <Box px={open ? 2 : '2px'} py={1}>
            <MainSidebarHeader open={open} setOpen={setOpen} />
            <MainSidebarNavigation open={open} setOpen={setOpen} />
          </Box>
        </PerfectScrollbar>
      </Box>
    </Drawer>
  );
};
