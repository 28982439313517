import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';

export interface INotification {
  type?: 'error' | 'info' | 'success' | 'warning';
  position?: 'interior' | 'external';
  duration?: number;
  key: number;
  message: string;
}

export interface ICreateNotificationProps {
  type?: 'error' | 'info' | 'success' | 'warning';
  position?: 'interior' | 'external';
  duration?: number;
  message: string;
}

interface notificationState {
  notifications: INotification[] | [];
}

const initialState: notificationState = {
  notifications: [],
};

const createNotification = ({
  type = 'error',
  message,
  position = 'interior',
  duration = 5000,
}: ICreateNotificationProps): INotification => {
  return {
    type,
    position,
    duration,
    message,
    key: new Date().getTime() + Math.random(),
  };
};

export const notifierSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<ICreateNotificationProps>
    ) => {
      state.notifications = [
        createNotification(action.payload),
        ...state.notifications,
      ];
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter((notice) => {
        return notice.key !== action.payload;
      });
    },
  },
});

export const { setNotification, removeNotification } = notifierSlice.actions;

export const notifications = (state: RootState) =>
  state.notifications.notifications;

export default notifierSlice.reducer;
