import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { auth } from './../features/authentication/sliceAuthentication';
import { showWLPages } from 'src/redux/AffiliateMember/sliceMembers';

export const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { user } = useSelector(auth);

  if (!Component) {
    return null;
  }

  return (
    <Route
      path={rest.path}
      exact
      render={(props) => {
        return user ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export const WlRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { user } = useSelector(auth);
  const isUserWL = useSelector(showWLPages);
  if (!Component || !isUserWL) {
    return null;
  }

  return (
    <Route
      path={rest.path}
      exact
      render={(props) => {
        return user ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export const AuthRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { user } = useSelector(auth);

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return !user ? <Component {...props} /> : <Redirect to="/dashboard" />;
      }}
    />
  );
};
