import React, { createContext, useContext, useState } from 'react';
import {
  IRegister,
  signUp,
} from 'src/features/authentication/sliceAuthentication';
import { useDispatch } from 'react-redux';
import validation, {
  Validation,
} from 'src/features/authentication/organisms/validation';

export interface IRegistrationForm {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
  username: string;
  address: string;
  business_type: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  fein_or_ssn: string;
  website: string;
  company: string;
}

const initialFormData = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  password: '',
  username: '',
  address: '',
  business_type: '',
  city: '',
  country: '',
  state: '',
  zip: '',
  fein_or_ssn: '',
  website: '',
  company: '',
};

interface ContextInterface {
  stepsFields: {
    mainInfo: string[][];
    additionalInfo: string[][];
  };
  selectedValue: boolean;
  setSelectedValue: React.Dispatch<React.SetStateAction<boolean>>;
  handleComplete: () => void;
  onSubmit: (data: IRegister) => void;
  handleBack: () => void;
  onHandleSubmit: (data: any) => void;
  formData: IRegistrationForm;
  setFormData: React.Dispatch<React.SetStateAction<IRegistrationForm>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleStep: (step: number) => () => void;
  loading: boolean;
  success: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  completed: {
    [k: number]: boolean;
  };
  setCompleted: React.Dispatch<
    React.SetStateAction<{
      [k: number]: boolean;
    }>
  >;
  error: Partial<Record<keyof IRegistrationForm, string>>;
  validateField: (field: string, value: string | number | boolean) => boolean;
  isNextWasClicked: boolean;
  setIsNextClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isForm: boolean;
  setIsForm: React.Dispatch<React.SetStateAction<boolean>>;
  completeError: string;
  setCompleteError: React.Dispatch<React.SetStateAction<string>>;
}

const initialData = {
  stepsFields: {
    mainInfo: [],
    additionalInfo: [],
  },
  selectedValue: false,
  setSelectedValue: (): void => {
    throw new Error('setContext function must be overridden');
  },
  validateField: () => {
    throw new Error('setContext function must be overridden');
  },
  handleComplete: (): void => {
    throw new Error('setContext function must be overridden');
  },
  onSubmit: (): void => {
    throw new Error('setContext function must be overridden');
  },
  handleBack: (): void => {
    throw new Error('setContext function must be overridden');
  },
  onHandleSubmit: (): void => {
    throw new Error('setContext function must be overridden');
  },
  formData: initialFormData,
  setFormData: () => {
    throw new Error('setContext function must be overridden');
  },
  activeStep: 0,
  setActiveStep: (): void => {
    throw new Error('setContext function must be overridden');
  },
  handleStep: () => {
    throw new Error('setContext function must be overridden');
  },
  loading: false,
  success: false,
  setLoading: (): void => {
    throw new Error('setContext function must be overridden');
  },
  setSuccess: (): void => {
    throw new Error('setContext function must be overridden');
  },
  completed: {},
  setCompleted: (): void => {
    throw new Error('setContext function must be overridden');
  },
  setError: (): void => {
    throw new Error('setContext function must be overridden');
  },
  error: {},
  isNextWasClicked: false,
  setIsNextClicked: () => {
    throw new Error('setContext function must be overridden');
  },
  isForm: false,
  setIsForm: () => {
    throw new Error('setContext function must be overridden');
  },
  completeError: '',
  setCompleteError: () => {
    throw new Error('setContext function must be overridden');
  },
};

export const Registration = createContext<ContextInterface>(initialData);

export function useRegistration() {
  return useContext(Registration);
}

export const RegistrationProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<
    Partial<Record<keyof IRegistrationForm, string>>
  >({});
  const [selectedValue, setSelectedValue] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isForm, setIsForm] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [isNextWasClicked, setIsNextClicked] = useState(false);
  const [completeError, setCompleteError] = useState('');
  const [formData, setFormData] = useState<IRegistrationForm>(initialFormData);

  const onHandleSubmit = (data: any) => {
    const updatedData = { ...data };
    onSubmit({ ...updatedData });
  };

  const onCompleteStep = (type: boolean) => {
    const newCompleted = completed;
    newCompleted[activeStep] = type;
    setCompleted(newCompleted);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    onCompleteStep(false);
  };

  const onCompleteSuccess = (step: number) => {
    onCompleteStep(true);
    setActiveStep(step);
  };

  const handleComplete = () => {
    onCompleteStep(true);
    if (activeStep === 0) {
      onCompleteSuccess(1);
    }
    if (activeStep === 1) {
      onCompleteSuccess(2);
    }
    if (activeStep === 2) {
      onCompleteSuccess(3);
    }
  };

  const afterRegister = (success: boolean) => {
    setSuccess(success);
    setLoading(false);
  };

  const onFailRegistration = (message: string) => {
    setCompleteError(message);
    setLoading(false);
    setSuccess(false);
    setCompleted({
      0: true,
      1: false,
      2: false,
    });
  };

  const onSubmit = (data: IRegister) => {
    setLoading(true);
    dispatch(signUp({ ...data }, afterRegister, onFailRegistration));
    setIsForm(false);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const validateField = (field: string, value: string | number | boolean) => {
    const validateRules = validation[field as keyof Validation];

    if (!validateRules) {
      return true;
    }

    if (validateRules?.required?.value && !value) {
      setError((prev) => ({
        ...prev,
        [field]: validateRules.required.message,
      }));

      return false;
    }

    if (validateRules?.pattern?.value && typeof value === 'string') {
      if (!value) {
        setError((prev) => ({
          ...prev,
          [field]: '',
        }));

        return true;
      }

      const isValid = validateRules?.pattern.value.test(value);

      if (!isValid) {
        setError((prev) => ({
          ...prev,
          [field]: validateRules.pattern?.message,
        }));

        return false;
      } else {
        setError((prev) => ({
          ...prev,
          [field]: '',
        }));
      }
    }

    if (validateRules.validate?.value && typeof value === 'string') {
      if (!value) {
        setError((prev) => ({
          ...prev,
          [field]: 'Fill in',
        }));
        return true;
      }

      const isValid = validateRules?.validate.value(value);

      if (!isValid) {
        setError((prev) => ({
          ...prev,
          [field]: 'Not correct',
        }));
        return false;
      } else {
        setError((prev) => ({
          ...prev,
          [field]: '',
        }));
      }
    }

    setError((prev) => ({
      ...prev,
      [field]: '',
    }));

    return true;
  };

  const stepsFields = {
    mainInfo: [
      ['first_name', 'last_name'],
      ['username', 'password'],
      ['email', 'phone_number'],
    ],
    additionalInfo: [],
  };

  const value = {
    selectedValue,
    setSelectedValue,
    handleComplete,
    handleBack,
    onHandleSubmit,
    activeStep,
    setActiveStep,
    handleStep,
    loading,
    success,
    setSuccess,
    setLoading,
    completed,
    setCompleted,
    onSubmit,
    formData,
    setFormData,
    error,
    validateField,
    isNextWasClicked,
    setIsNextClicked,
    stepsFields,
    setIsForm,
    isForm,
    completeError,
    setCompleteError,
  };

  return (
    <Registration.Provider value={value}>{children}</Registration.Provider>
  );
};
