import React from 'react';
import { useSelector } from 'react-redux';
import { notifications, INotification } from '../sliceNotifications';
import { Notification } from './Notification';
import { useStyles } from './styles';

export const Notifications = () => {
  const classes = useStyles();
  const activeNotifications = useSelector(notifications);

  if (!activeNotifications.length) {
    return null;
  }

  const interiorNotifications = activeNotifications.filter(
    (notification) => notification.position === 'interior'
  );
  const externalNotifications = activeNotifications.filter(
    (notification) => notification.position === 'external'
  );

  return (
    <>
      {interiorNotifications && (
        <div className={classes.interior}>
          {(interiorNotifications as Array<INotification>).map(
            (notification: INotification) => (
              <Notification
                notification={notification}
                key={notification.key}
              />
            )
          )}
        </div>
      )}
      {externalNotifications && (
        <div className={classes.external}>
          {(externalNotifications as Array<INotification>).map(
            (notification: INotification) => (
              <Notification
                notification={notification}
                key={notification.key}
              />
            )
          )}
        </div>
      )}
    </>
  );
};
