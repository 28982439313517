import { makeStyles, createTheme } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors/';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: Palette.white,
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  },
  card: {
    position: 'relative',
    maxWidth: '95%',
    margin: '0 4%',
    paddingBottom: '36px',
    boxShadow: '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
    borderRadius: '15px',
    '@media (min-width: 800px)': {
      width:'640px',
    },
  },
  cardContent: {
    padding: '48px 24px',
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#5ACC6A',
      light: '#5EE071',
      dark: '#50BF60',
      contrastText: '#fff',
    },
    secondary: deepOrange,
  },
});
