import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import MuiTheme from './themes/theme';
import Routes from './routes';
import {
  auth,
  getCurrentUser,
  selectLoadingUser,
  setCurrentUser,
} from './features/authentication/sliceAuthentication';
import { Notifications } from 'src/features/notifications';
import { Spiner } from 'src/components/Loaders';
import { GET_AFFILIATE_MEMBER } from './api/affiliates';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const [loadUser] = useLazyQuery(GET_AFFILIATE_MEMBER, {
    onCompleted: (data) => {
      dispatch(
        setCurrentUser({
          ...user,
          given_name: data.v1_affiliates_readMember.firstName,
          family_name: data.v1_affiliates_readMember.lastName,
          'cognito:username': data.v1_affiliates_readMember.userName,
        })
      );
    },
  });
  const isLoadingUser = useSelector(selectLoadingUser);

  useEffect(() => {
    dispatch(getCurrentUser(loadUser));
  }, [dispatch, loadUser]);

  return (
    <MuiTheme>
      <Notifications />
      {isLoadingUser ? <Spiner fixed /> : <Routes />}
    </MuiTheme>
  );
}

export default App;
