import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  interior: {
    position: 'fixed',
    zIndex: 1400,
    width: '100%',
    maxWidth: '640px',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  external: {
    position: 'fixed',
    zIndex: 1400,
    width: '100%',
    maxWidth: '348px',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
}));
