/* eslint-disable no-useless-escape */
import parsePhoneNumber from 'libphonenumber-js';

type validRule = {
  required: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: RegExp;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  validate?: {
    value: (v: string | undefined) => boolean;
  };
};
export interface Validation {
  email: validRule;
  password: validRule;
  confirmPassword: validRule;
  first_name: validRule;
  username: validRule;
  website: validRule;
  last_name: validRule;
  phone_number: validRule;
}

const validation: Validation = {
  email: {
    required: {
      value: true,
      message: 'Write your email',
    },
    pattern: {
      value:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Email is invalid',
    },
  },
  password: {
    required: {
      value: true,
      message: 'Write your password',
    },
    minLength: {
      value: 8,
      message: 'Must include min 8 Chars',
    },
    pattern: {
      value:
        /(?=^.{8,}$)((?=.*\d)((?=.*\W+)|(?=.*\_)))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      message: 'Must include UpperCase, LowerCase, Number/SpecialChar',
    },
  },
  confirmPassword: {
    required: {
      value: true,
      message: 'Repeat password',
    },
    minLength: {
      value: 8,
      message: 'Repeat password',
    },
  },
  first_name: {
    required: {
      value: true,
      message: 'Write your first name',
    },
    pattern: {
      value: /^[A-Za-z0-9.-]{2,24}$/,
      message: 'Can only contain letters',
    },
  },
  username: {
    required: {
      value: true,
      message: 'Write your username',
    },
    pattern: {
      value: /^[A-Za-z0-9-]{2,24}$/,
      message: 'Can only contain letters, numbers & dash',
    },
  },
  website: {
    required: {
      value: true,
      message: 'Write your website',
    },
    pattern: {
      value:
        /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/,
      message: 'Write correct website',
    },
  },
  last_name: {
    required: {
      value: true,
      message: 'Write your last name',
    },
    pattern: {
      value: /^[A-Za-z0-9.-]{2,24}$/,
      message: 'Can only contain letters',
    },
  },
  phone_number: {
    required: {
      value: true,
      message: 'Write your phone number',
    },
    validate: {
      value: (v: string | undefined) => {
        const phoneNumber = parsePhoneNumber(v || '', 'US');
        return phoneNumber?.isValid() || false;
      },
    },
  },
};

export default validation;
