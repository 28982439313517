import { makeStyles } from '@material-ui/core/styles';
import { Palette } from 'src/themes/Palette';

export const useStyles = makeStyles(() => ({
  interior: {
    position: 'fixed',
    zIndex: 1400,
    width: '100%',
    maxWidth: '640px',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  external: {
    position: 'fixed',
    zIndex: 1400,
    width: '100%',
    maxWidth: '348px',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',
    borderRadius: '30px', 
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '12px',
    padding: '0 20px 0 33px',
    background: (props: any) =>
    props.notification.type === 'error' ? Palette.error.background : 
    props.notification.type === 'warning' ? Palette.warning.background :
    props.notification.type === 'info' ? Palette.info.background : Palette.success.background,
    color: (props: any) =>
    props.notification.type === 'error' ? Palette.error.text : 
    props.notification.type === 'warning' ? Palette.warning.text :
    props.notification.type === 'info' ? Palette.info.text : Palette.success.text,
    border: (props: any) =>
    props.notification.type === 'error' ? `0.6px solid ${Palette.error.border}` : 
    props.notification.type === 'warning' ? `0.6px solid ${Palette.warning.border}` :
    props.notification.type === 'info' ? `0.6px solid ${Palette.info.border}` : `0.6px solid ${Palette.success.border}`,
    '& .MuiAlert-icon': {
      padding: '12px 0',
    },
    '& .MuiAlert-message': {
      padding: '14px 0',
    },
    '@media (max-width: 600px)': {
      padding: '0 20px',
    },
  },
  closeBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: (props: any) =>
    props.notification.type === 'error' ? Palette.error.close : 
    props.notification.type === 'warning' ? Palette.warning.close :
    props.notification.type === 'info' ? Palette.info.close : Palette.success.close,
    opacity: 0.5,
    width: '36px', 
    height: '36px',
    borderRadius: '43px',
    cursor: 'pointer',
  }
}));
